// ====================================
// SOCIAL STYLES
// ====================================

.social-list {
  list-style-type: none;
}

.social-list__link {
  display: flex;
  align-items: center;

  color: $color-ui--grey-mid;
  text-decoration: none;

    svg {
      width: 2rem;
      height: 2rem;
      margin: 0 1rem 0 0;
    }

    &:hover {
      color: $color-brand--red;
    }

}

// Variants

.pg-footer-social {

  .social-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin: -1rem;

      @include mq(screen--xs) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
      }

      @include mq(screen--md) {
        flex-direction: column;
        justify-content: flex-start;
      }
  }

  .social-list__item {
    padding: 1rem;

    font-size: $font-size--xs
  }

}

.contact-info {

  .social-list {
    margin: -1rem;
  }

  .social-list__item {
    padding: 1rem;
  }

}