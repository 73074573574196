// =================================================
// SPACING STYLES
// =================================================

// Margin top

.mt-sm {
  margin-top: 1rem;

    @include mq(screen--lg) {
      margin-top: $padding--sm;
    }

}

.mt-md {
  margin-top: $padding--sm;

    @include mq(screen--lg) {
      margin-top: $padding--md;
    }

}

.mt-lg {
  margin-top: $padding--md;

    @include mq(screen--lg) {
      margin-top: $padding--lg;
    }

}

.mt-xl {
  margin-top: $padding--lg;

    @include mq(screen--lg) {
      margin-top: $padding--xl;
    }
    
}

// Margin bottom

.mb-sm {
  margin-bottom: 1rem;

    @include mq(screen--lg) {
      margin-bottom: $padding--sm;
    }

}

.mb-md {
  margin-bottom: $padding--sm;

    @include mq(screen--lg) {
      margin-bottom: $padding--md;
    }

}

.mb-lg {
  margin-bottom: $padding--md;

    @include mq(screen--lg) {
      margin-bottom: $padding--lg;
    }

}

.mb-xl {
  margin-bottom: $padding--lg;

    @include mq(screen--lg) {
      margin-bottom: $padding--xl;
    }
    
}

// Margin

.mtb-sm {
  margin: 1rem 0;

    @include mq(screen--lg) {
      margin: $padding--sm 0;
    }

}

.mtb-md {
  margin: $padding--sm 0;

    @include mq(screen--lg) {
      margin: $padding--md 0;
    }

}

.mtb-lg {
  margin: $padding--md 0;

    @include mq(screen--lg) {
      margin: $padding--lg 0;
    }

}

.mtb-xl {
  margin: $padding--lg 0;

    @include mq(screen--lg) {
      margin: $padding--xl 0;
    }
    
}

// Padding top

.pt--sm {
  padding-top: 1rem;

    @include mq(screen--lg) {
      padding-top: $padding--sm;
    }

}

.pt-md {
  padding-top: $padding--sm;

    @include mq(screen--lg) {
      padding-top: $padding--md;
    }

}

.pt-lg {
  padding-top: $padding--md;

    @include mq(screen--lg) {
      padding-top: $padding--lg;
    }

}

.pt-xl {
  padding-top: $padding--lg;

    @include mq(screen--lg) {
      padding-top: $padding--xl;
    }
    
}

// Padding bottom

.pb-sm {
  padding-bottom: 1rem;

    @include mq(screen--lg) {
      padding-bottom: $padding--sm;
    }

}

.pb-md {
  padding-bottom: $padding--sm;

    @include mq(screen--lg) {
      padding-bottom: $padding--md;
    }

}

.pb-lg {
  padding-bottom: $padding--md;

    @include mq(screen--lg) {
      padding-bottom: $padding--lg;
    }

}

.pb-xl {
  padding-bottom: $padding--lg;

    @include mq(screen--lg) {
      padding-bottom: $padding--xl;
    }
    
}

// Padding

.ptb-sm {
  padding: 1rem 0;

    @include mq(screen--lg) {
      padding: $padding--sm 0;
    }

}

.ptb-md {
  padding: $padding--sm 0;

    @include mq(screen--lg) {
      padding: $padding--md 0;
    }

}

.ptb-lg {
  padding: $padding--md 0;

    @include mq(screen--lg) {
      padding: $padding--lg 0;
    }

}

.ptb-xl {
  padding: $padding--lg 0;

    @include mq(screen--lg) {
      padding: $padding--xl 0;
    }
    
}