// =================================================
// PAGE HEADER STYLES
// =================================================

.pg-header {
  position: relative;
  z-index: 10;

    @include mq(screen--sm) {
      margin: 0 0 $padding--md;
      padding: 0 $padding--lg;
    }

    @include mq(screen--lg) {
      width: $screen--lg;
      margin: 0 auto $padding--md;
    }

}

.pg-header-tel-bar {
  display: none;

    @include mq(screen--sm) {
      display: flex;
      justify-content: flex-end;
      padding: $padding--sm 0;
    }

}

.pg-header-tel {
  display: flex;
  align-items: center;

  font-size: $font-size--xs;
  font-weight: $font-weight--bold;
  color: $color-brand--blue;
  text-decoration: none;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 0.5rem 0 0;
      
      fill: currentColor;
    }

    &:hover {
      color: $color-brand--red;
    }

}

.pg-header-nav {
  
  @include mq(screen--sm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $padding--xl 0 0;
  }

  @include mq(screen--lg) {
    padding: 0;
  }
}

.pg-header-logo {
  position: relative;
  display: block;
  width: 12.5rem;
  margin: $padding--sm auto;

    @include mq(screen--sm) {
      width: 15rem;
      margin: 0;
    }

}

.pg-header-logo__img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
}

.poppy-icon {
  position: absolute;
  top: 0;
  right: -2rem;
  z-index: 2;

  width: 2rem;
  height: 2rem;

    img {
      @include flex-img;
    }

}

.main-nav-toggle {
  position: absolute;
  top: 0;
  right: $padding--md;
  z-index: 1;

  display: block;
  width: 5rem;
  height: 5rem;
  padding: 1rem;

  background: none;

    @include mq(screen--sm) {
      top: 5.25rem;
      right: $padding--lg;
    }

    @include mq(screen--lg) {
      display: none;
    }

    svg {
      width: 3rem;
      height: 3rem;

      fill: $color-brand--blue;
    }

    svg:last-child {
      display: none;
    }


}

.main-nav-toggle.is-active {
  
  svg:first-child {
    display: none;
  }

  svg:last-child {
    display: block;
  }

}

.main-nav {
  position: absolute;
  top: 6.5rem;
  right: 0;
  z-index: 1;

  width: 100%;

  background-color: white;
  box-shadow: $drop-shadow;

  display: none;
  visibility: hidden;
  opacity: 0;

    @include mq(screen--sm) {
      top: 11.5rem;

      width: 50%;
    }

    @include mq(screen--lg) {
      position: relative;
      top: 0;

      display: block;
      padding: 0 $padding--lg 0 0;
      visibility: visible;
      opacity: 1;

      box-shadow: none;

      flex: 1;
    }

    &.is-open {
      display: block;
      visibility: visible;
      opacity: 1;
    }

}

.main-nav__list,
.main-nav__list ul {
  list-style-type: none;
}

.main-nav__list {
    
  @include mq(screen--lg) {
    display: flex;
    justify-content: flex-end;
  }

  // Level 1 styles

  > li {
    
    @include mq(screen--lg) {
      position: relative;
      padding: $padding--md 0 $padding--md $padding--lg;

      &:hover {

        > a {
          color: $color-brand--red;
        }
        
        > ul {
          display: block;
          visibility: visible;
          opacity: 1;
        }

      }

    }

  }

  > li > a {
    display: block;
    padding: 1.5rem;

    font-size: $font-size--xs;
    color: $color-ui--grey-mid;
    text-decoration: none;

      @include mq(screen--lg) {
        padding: 0;

        &:hover {
          color: $color-brand--red;
        }
      }

  }

  // Level 2 styles

  ul {
    
    @include mq(screen--lg) {
      position: absolute;
      top: 6rem;
      z-index: 10000;

      display: none;
      visibility: hidden;
      opacity: 0;

      width: 30rem;
      padding: 0.5rem 0;

      box-shadow: $drop-shadow;
      border-radius: $border-radius--md;
      background-color: white;
      transition: $transition--sm;
    }

  }

  ul > li > a {
    display: block;
    padding: 1.5rem 1.5rem;

    font-size: $font-size--xs;
    color: $color-ui--grey-mid;
    text-decoration: none;

    border-left: 5px solid $color-brand--red;

      @include mq(screen--lg) {
        padding: 1rem 1.5rem;
        border: none;

        &:hover {
          color: $color-brand--red;
        }
      }

  }

  ul > li:first-child > a {
    
    @include mq(screen--lg) {
      font-weight: $font-weight--bold;
      color: $color-brand--blue;
    }

  }

}

// WP styles

.main-nav__list li.current_page_item > a,
.main-nav__list li.current_page_parent > a,
.main-nav__list li.current_page_parent li.current-menu-item > a {
  font-weight: $font-weight--bold;
  text-decoration: underline;
}

.cta-nav {
  display: flex;
}

.cta-nav-btn {
  display: block;
  width: 50%;
  height: 5rem;

  font-size: $font-size--xxs;
  font-weight: $font-weight--bold;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 5rem;
  white-space: nowrap;

  transition: $transition--sm;


    @include mq(screen--sm) {
      width: auto;
      height: 4rem;
      padding: 0 1rem;

      line-height: 4rem;

      border-radius: $border-radius--md;
    }

    @include mq(screen--lg) {
      position: relative;

      display: block;
      padding: 0 3.5rem 0 1rem;

      font-size: $font-size--xs;
    }

    svg {
      display: none;

        @include mq(screen--lg) {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          z-index: 1;

          display: block;
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 0 0 1rem;

          fill: currentColor;
        }

    }

}

.cta-nav-btn--blue {
  background-color: $color-brand--blue;

    &:hover {
      background-color: $color-brand--blue-hover;
    }

}

.cta-nav-btn--red {
  background-color: $color-brand--red;

    &:hover {
      background-color: $color-brand--red-hover;
    }

    @include mq(screen--sm) {
      margin: 0 0 0 1rem;
    }

}

// WP styles

.main-nav__list {

  .menu-item-has-children {
    background-color: $color-ui--grey-light;

      @include mq(screen--lg) {
        background: none;
      }

  }

}