// ====================================
// UPDATE BANNER STYLES
// ====================================

.update-banner {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  padding: $padding--sm 4rem $padding--sm $padding--sm;

  background-color: $color-brand--blue;

    @include mq(screen--xs) {
      padding: $padding--sm 4rem;
    }
}

.update-banner__text {
  font-size: $font-size--xxs;
  line-height: $line-height--md;
  color: white;
}

.close-update-banner {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;

  display: block;
  width: 4rem;
  height: 4rem;
  padding: 0 1.25rem;

  background: none;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: white;
    }

}