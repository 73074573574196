/*!
Theme Name: Building Heroes Property Services
Theme URI:
Author: Abbas Arezoo
Author URI: http://www.abbasarezoo.com
Description: Building Heroes Property Services Wordpress theme
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/

// * {
//   outline: 1px solid red;
// }

@import "ui/media-queries";
@import "ui/fonts";
@import "ui/variables";
@import "ui/mixins";
@import "ui/reset";

@import "plugins/tiny-slider";

@import "layout/global";
@import "layout/header";
@import "layout/footer";
@import "layout/layout";
@import "layout/spacing";

@import "components/hero";
@import "components/buttons";
@import "components/sub-title";
@import "components/img-gallery";
@import "components/services-grid";
@import "components/tooltip";
@import "components/promo";
@import "components/tradesperson-promo";
@import "components/contact-info";
@import "components/std-content";
@import "components/forms";
@import "components/cta-panel";
@import "components/how-it-works";
@import "components/filter-list";
@import "components/feed";
@import "components/pagination";
@import "components/social";
@import "components/update-banner";
@import "components/newsletter-signup";
@import "components/tables";
@import "components/cookies";
@import "components/404";


