// ====================================
// FORM STYLES
// ====================================

.contact-form br {
  height: 0;
}

// Layout

.contact-form__intro {
  margin: 0 0 $padding--lg;
  padding: 0 0 $padding--lg;

  border-bottom: 1px solid $color-ui--grey-light;
}

.inner--lg .contact-form-section {
  
  @include mq(screen--md) {
    width: $screen--sm;
  }

}

.contact-form-section + .contact-form-section {
  margin: $padding--lg 0 0;
  padding: $padding--lg 0 0;

  border-top: 1px solid $color-ui--grey-light;
}

.contact-form-marker {
  display: inline-block;
  height: 4rem;
  padding: 0 1rem;
  margin: 0 0 $padding--md;

  font-weight: $font-weight--bold;
  font-size: $font-size--xs;
  color: $color-brand--blue;
  line-height: 4rem;

  border-radius: 2.5rem;
  background-color: $color-brand--green;

    @include mq(screen--sm) {
      margin: 0 0 $padding--lg;
    }

}

.contact-form-helpers {
  margin: 0 0 $padding--md 2rem;

  font-size: $font-size--xs;
  line-height: $line-height--md;

    @include mq(screen--sm) {
      margin: 0 0 $padding--lg 2rem;
    }

}

.contact-form-gdpr {
  display: flex;
  padding: $padding--md;

  border-radius: $border-radius--md;
  background-color: $color-ui--grey-light;

    label {
      flex: 1;

      font-size: $font-size--xxs;
      line-height: $line-height--md;
    }

    input {
      margin: 0.5rem 1rem 0 0;
    }

    a {
      color: $color-brand--red;

        &:hover {
          color: $color-brand--blue;
        }
        
    }

}

.contact-form-gdpr .wpcf7-list-item > label {
  display: flex;
}

// Base field styles

.contact-form-row + .contact-form-row {
  margin: $padding--md 0 0;

    @include mq(screen--sm) {
      margin: 3rem 0 0;
    }

}

.contact-form-row__label {
  display: block;
  margin: 0 0 1rem;

  font-weight: $font-weight--bold;
  font-size: $font-size--xs;
  color: $color-brand--blue;

    @include mq(screen--lg) {
      font-size: $font-size--sm;
    }

}

// Base styles

.contact-form-row,
.contact-form-fields {

  input[type=text],
  input[type=tel],
  input[type=email],
	input[type=url],
	input[type=password] {
      width: 100%;
      height: 5rem;
      padding: 0 1rem;

      border: 1px solid $color-brand--blue;
      border-radius: 3px;
      background-color: white;

      font-family: 'Cabin', sans-serif;
      font-size: $font-size--xs;

        @include mq(screen--sm) {
          height: 6rem;
        }

				&.wpcf7-not-valid {
					border: 3px solid $color-validate--fail;
        }
        
        &:focus {
          border: 3px solid $color-brand--red;
          outline: none;
        }

  }

  textarea {
    width: 100%;
    height: 25rem;
    padding: 1rem;

    border: 1px solid $color-brand--blue;
    border-radius: 3px;
    background-color: white;

    font-family: 'Cabin', sans-serif;
    font-size: $font-size--xs;

      @include mq(screen--sm) {
        height: 30rem;
        padding: 2rem 1rem;
      }
		
			&.wpcf7-not-valid {
				border: 3px solid $color-validate--fail;
      }
      
      &:focus {
        border: 3px solid $color-brand--red;
        outline: none;
      }

  }

  select {
      width: 100%;
      height: 5rem;

      border: 1px solid $color-brand--blue;
      background-color: white;

      font-size: $font-size--xs;
  }

}

.submit-btn {
  position: relative;

  background-color: $color-brand--red;
  border-radius: $border-radius--md;
  overflow: hidden;

    &:hover {
      background-color: $color-brand--red-hover;
    }

    input[type="submit"] {
      position: relative;
      z-index: 2;

      height: 5rem;
      padding: 0 5rem 0 1rem;

      font-family: 'Cabin', sans-serif;
      font-size: $font-size--sm;
      font-weight: $font-weight--bold;
      color: white;
      line-height: 5rem;

      border: none;
      transition: $transition--sm;
      background: none;

      cursor: pointer;

        @include mq(screen--sm) {
          height: 6rem;
          padding: 0 6rem 0 1.5rem;
        }

    }

    svg {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%);
      z-index: 1;

      width: 1.5rem;
      height: 1.5rem;

      fill: white;

        @include mq(screen--sm) {
          width: 2rem;
          height: 2rem;
          right: 2rem;
        }

    }

}

// Custom checkboxes and radios

.contact-form-checkboxes,
.contact-form-services > span {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 $padding--md;

    @include mq(screen--sm) {
      margin: 0 0 $padding--lg;
    }

}

.contact-form-checkbox,
.contact-form-services .wpcf7-list-item {
  margin: 0 1rem 1rem 0;
}

.contact-form-checkbox > input[type="checkbox"] {
  display: none;
}

.contact-form-checkbox > label {
  display: block;
  height: 4rem;
  padding: 0 1rem;

  font-weight: $font-weight--bold;
  font-size: $font-size--xs;
  color: $color-brand--blue;
  line-height: calc(4rem - 4px);

  border: 2px solid $color-ui--grey-mid;
  border-radius: 2rem;

    @include mq(screen--sm) {
      height: 5rem;
      padding: 0 2rem;

      font-size: $font-size--sm;
      line-height: calc(5rem - 4px);

      border-radius: 2.5rem;
    }

}

.contact-form-checkbox > label:hover {
  background-color: $color-ui--grey-light;
  cursor: pointer;
}

.contact-form-checkbox > input[type="checkbox"]:checked ~ label {
  background-color: $color-brand--blue;
  border-color: $color-brand--blue;
  color: white;
}

.contact-form-radios {
  margin: 0 0 $padding--lg;
}

.contact-form-radios__label,
.contact-form-radio,
.contact-form-graduate label {
  display: block;
  font-weight: $font-weight--bold;
  color: $color-brand--blue;
}

.contact-form-radios__label {
  margin: 0 0 $padding--md;
}

.contact-form-radio + .contact-form-radio,
.contact-form-graduate span.wpcf7-list-item + span.wpcf7-list-item {
  margin: $padding--md 0 0;
}

.contact-form-graduate span.wpcf7-list-item {
  display: block;

    label {
      display: flex;
    }

    input {
      margin: 0.5rem 1rem 0 0;

        @include mq(screen--lg) {
          margin: 1rem 1rem 0 0;
        }

    }

    .wpcf7-list-item-label {
      flex: 1;

      line-height: $line-height--md;
    }

}

// Actions

.contact-form-actions {
  display: flex;
  margin: $padding--md 0 0;

    @include mq(screen--sm) {
      margin: $padding--lg 0 0;
    }

}

.next-step {
  font-family: 'Cabin', sans-serif;

    svg {
      transform: translateY(-50%) rotate(90deg);
    }

}

.previous-step {
  margin: 0 0 0 2rem;

  border: none;
  background: none;

  font-family: 'Cabin', sans-serif;
  font-size: $font-size--xs;
  color: $color-ui--grey-mid;
  text-decoration: none;

    &:hover {
      color: $color-brand--red;
      text-decoration: underline;
    }

}

// Validation

.not-valid {
	display: block;
	padding: $padding--sm;
	margin: 1rem 0 0;

	background-color: $color-validate--fail;

	font-size: $font-size--xs;
	color: white;
}

// CF7 specific

form.wpcf7-form.sent,
form.wpcf7-form.invalid {
  padding: 0 0 $padding--lg;
}

div.wpcf7 .ajax-loader {
	display: none !important;
}

.screen-reader-response {
	display: none;
}

span.wpcf7-not-valid-tip {
	display: block;
	padding: $padding--sm;
	margin: 1rem 0 0;

  background-color: $color-validate--fail;
  border-radius: 3px;

	font-size: $font-size--xs;
	color: white;
}

div.wpcf7-validation-errors,
div.wpcf7-mail-sent-ng,
div.wpcf7-mail-sent-ok {
	border: none !important;
}

div.wpcf7-response-output {
	margin: 0 !important;
  padding: 0;
  
  font-size: $font-size--xs;
  font-weight: $font-weight--bold;
  color: white;
  
  border-radius: 3px;
	
		&.wpcf7-validation-errors {
      margin: $padding--md 0 0 !important;
			padding: $padding--sm;
			background-color: $color-validate--fail;
		}

		&.wpcf7-validation-success,
		&.wpcf7-mail-sent-ok {
      margin: $padding--md 0 0 !important;
			padding: $padding--sm;
			background-color: $color-validate--ok;
		}

}

.wpcf7-form.invalid div.wpcf7-response-output,
.wpcf7-form.spam div.wpcf7-response-output,
.wpcf7-form.success div.wpcf7-response-output,
.wpcf7-form.sent div.wpcf7-response-output {
  margin: $padding--md 0 0 !important;
  padding: $padding--sm;
  
  border-radius: 3px;
}

.wpcf7-form.invalid div.wpcf7-response-output,
.wpcf7-form.spam div.wpcf7-response-output {
  background-color: $color-validate--fail;
}

.wpcf7-form.sent div.wpcf7-response-output,
.wpcf7-form.success div.wpcf7-response-output {
  background-color: $color-validate--ok;
}

// Variants

.promo--blue,
.promo--red {

  .contact-form-helper {
    color: white;
  }

  .contact-form-row__label {
    color: white;
  }

  .contact-form-checkbox > label {
    color: white;
  
    border: 2px solid rgba(white,0.2);
  }
  
  .contact-form-checkbox > label:hover {
    background-color: rgba(white, 0.1);
  }
  
  .contact-form-checkbox > input[type="checkbox"]:checked ~ label {
    background-color: $color-brand--red;
    border-color: $color-brand--red;
  }

  .contact-form-gdpr label {
    color: white;
  }

  .contact-form-gdpr label a {
    color: white;
  }

  .previous-step {
    color: white;
  }

}

.overlap {

  .contact-form {
    padding: 0 $padding--md;
    
    @include mq(screen--sm) {
      max-width: $screen--sm;
      margin: 0 auto;
      padding: 0 $padding--lg;
    }

  }

}

// Custom CF7 checkboxes

.contact-form-services {

  .wpcf7-checkbox input[type=checkbox] {
    position: absolute;
    visibility: hidden;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
  }

  .wpcf7-checkbox input[type=checkbox] + span {
    display: block;
    height: 4rem;
    padding: 0 1rem;

    font-weight: $font-weight--bold;
    font-size: $font-size--xs;
    color: $color-brand--blue;
    line-height: calc(4rem - 4px);

    border: 2px solid $color-ui--grey-mid;
    border-radius: 2rem;

    @include mq(screen--sm) {
      height: 5rem;
      padding: 0 2rem;

      font-size: $font-size--sm;
      line-height: calc(5rem - 4px);

      border-radius: 2.5rem;
    }

      &:hover {
        background-color: $color-ui--grey-light;
        cursor: pointer;
      }

  }

  .wpcf7-checkbox input[type=checkbox]:checked + span {
    background-color: $color-brand--blue;
    border-color: $color-brand--blue;
    color: white;
  }

}