html,
body {
	border: none;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
	border: none;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0;
	text-align: inherit;
}

blockquote::before,
blockquote::after {
	content: "";
}

html {
	font-size: 62.5%; /* 1rem = 10px */
	scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

body {
	font-family: 'Cabin', sans-serif;
	font-size: $font-size--xs;
	font-weight: 400;
	color: $color-ui--grey-mid;

		@include mq(screen--lg) {
			font-size: $font-size--sm;
		}
		
}


*,
*::before,
*::after {
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
	word-break: break-word;
	word-wrap: break-word;
}

@media ( prefers-reduced-motion: reduce ) {

	* {
		animation-duration: 0s !important;
		transition-duration: 0s !important;
  }
  
}


input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
	-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
	border-radius: 0;
	box-shadow: none;

	font-family: inherit;
}

button {
	cursor: pointer;
}

.no-object-fit .object-fit {
  @include object-fit-support;
}

.no-scroll {
  overflow: hidden;
}

* {
	box-sizing: border-box;
}