// =================================================
// FILTER LIST STYLES
// =================================================

.filter-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -0.5rem;

  list-style-type: none;
}

.filter-list__item {
  padding: 0.5rem;
}

.filter-list__filter {
  display: block;
  height: 4rem;
  padding: 0 1rem;

  font-family: inherit;
  font-weight: $font-weight--bold;
  font-size: $font-size--xs;
  color: $color-brand--blue;
  line-height: calc(4rem - 4px);

  border: 2px solid $color-ui--grey-mid;
  border-radius: 2rem;

    &:hover {
      background-color: white;
      cursor: pointer;
    }

    &.mixitup-control-active {
      border-color: $color-brand--red;
      background-color: $color-brand--red;

      color: white;
    }
  
}

// Variants

.hero .filter-list {
  margin: $padding--lg 0 0;
}