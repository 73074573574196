// =================================================
// BUTTON STYLES
// =================================================

.btn-group {
  display: flex;
  flex-wrap: wrap;
  

    .btn {
      margin: 0 1rem 1rem 0;
    }

}

.btn-group--align-center {
  justify-content: center;
}

.btn {
  position: relative;
  display: block;
  height: 4rem;
  padding: 0 3.5rem 0 1rem;

  font-family: 'Cabin', sans-serif;
  font-size: $font-size--xs;
  font-weight: $font-weight--bold;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 4rem;
  white-space: nowrap;

  border-radius: $border-radius--md;
  transition: $transition--sm;

    svg {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      z-index: 1;

      display: block;
      width: 1.2rem;
      height: 1.2rem;

      fill: currentColor;
    }

}

.btn--blue {
  background-color: $color-brand--blue;

    &:hover {
      background-color: $color-brand--blue-hover;
    }

}

.btn--red {
  background-color: $color-brand--red;

    &:hover {
      background-color: $color-brand--red-hover;
    }

}

.btn--down svg {
  transform: translateY(-50%) rotate(90deg);
}
