// ====================================
// FORM STYLES
// ====================================

.promo {
  position: relative;
  padding: $padding--lg 0;

  // color: white;

    > .inner {
      position: relative;
      z-index: 2;
    }

    .sub-title {
      color: white;
    }

    .contact-form-gdpr {
      background-color: rgba(white, 0.2);
    }

    @include mq(screen--sm) {
      padding: $padding--xl 0;
    }

    &.with-overlap {
      
      @include mq(screen--lg) {
        padding: $padding--xl 0 $padding--xl*2;
      }

    }

    &:after {
      @include top-gradient-promo;
    }

}

.promo__bg {
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  display: none;
  width: 100%;
  opacity: 0.2;

    @include mq(screen--sm) {
      display: block;
    }

    svg {
      width: 100%;
      height: auto;
    }

}

.promo--blue {
  background: $grad-brand--blue;
}

.promo--red {
  background: $grad-brand--red;
}