// ====================================
// IMAGES
// ====================================

@mixin bkg-cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

@mixin flex-img {
	display: block;
	width: 100%;
	height: auto;
}

@mixin rev-flex-img {
	display: block;
	width: auto;
	height: 100%;
}

@mixin object-fit {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@mixin object-fit-support {

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { // IE 10 and 11
    background-size: cover;
    background-position: center;

      img {
        opacity: 0;
        transition: none;
      }

  }
  
  @supports (-ms-accelerator:true) { // IE Edge
    background-size: cover;
    background-position: center;

      img {
        opacity: 0;
        transition: none;
      }

  }
		
}

@mixin ie-support {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {@content;}
	@supports (-ms-accelerator:true) {@content;}
}

@mixin top-gradient {
  content: "";

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 1rem;

  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.075) 100%);
}

@mixin top-gradient-promo {
  content: "";

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 1rem;

  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 100%);
}

// ====================================
// TYPE
// ====================================


@mixin placeholder {  
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

@mixin text-highlight {
	
	&::selection {@content}

}