// ====================================
// TABLE STYLES
// ====================================

.custom-table {
  position: relative;

  font-size: $font-size--xxs;
  overflow: auto;

  border: 2px solid $color-brand--blue;

    @include mq(screen--lg) {
      font-size: $font-size--xs;
    }

    table {
      position: relative;
      z-index: 1;

      width: $screen--sm;
      table-layout: fixed;

        @include mq(screen--sm) {
          width: 100%;
        }

    }

    th, td {
      text-align: left;
      line-height: $line-height--md;
    }

    th {
      padding: 1.5rem;

      color: white;
      background-color: $color-brand--blue;
    }

    td {
      padding: 2rem;
    }

    tr > td:not(:last-child) {
      border-right: 1px solid $color-ui--grey-light;
    }

    tr:not(:last-child) > td {
      border-bottom: 1px solid $color-ui--grey-light;
    }

}

.custom-table__helper {
  display: inline-block;
  padding: 1rem;
  margin: $padding--md 0 0;

  font-size: $font-size--xxs;
  color: $color-brand--red;

  box-shadow: $drop-shadow;

    @include mq(screen--sm) {
      display: none;
    }

}