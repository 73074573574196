// =================================================
// HERO STYLES
// =================================================

.hero {
  position: relative;
  overflow: hidden;
  z-index: 1;

    @include mq(screen--sm) {
      min-height: 30rem;
    }

    @include mq(screen--lg) {
      min-height: 45rem;
    }

    > .inner {
      position: relative;
      z-index: 2;
    }

}

.hero-content {
  padding: $padding--lg 0;

    @include mq(screen--lg) {
      padding: $padding--xl 0;
    }

    .btn-group {
      margin: $padding--lg 0 0;
    }

}

.hero-content--centered {
  
  @include mq(screen--sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    text-align: center;
  }

}

.hero-content__text {
  margin: $padding--sm 0 0;

  line-height: $line-height--lg;
  color: $color-brand--blue;

    @include mq(screen--lg) {
      margin: $padding--md 0 0;
    }

    @include ie-support {
      width: 100%;
    }

}

.hero-content__date {
  display: block;
  margin: 0 0 $padding--md;

  font-weight: $font-weight--bold;
}

.hero-content__title {
  font-size: $font-size--md;
  font-weight: $font-weight--bold;

    @include mq(screen--sm) {
      font-size: $font-size--lg;
    }

    @include mq(screen--lg) {
      font-size: $font-size--xl;
    }

}

.hero-content__categories {
  padding: $padding--lg 0 0;

  @extend .posts-feed-item__cats;
}

.hero-content__category {
  @extend .posts-feed-item__cat;
}

.hero-icon {
  width: 6rem;
  height: 6rem;
  padding: 1.5rem;
  margin: 0 0 $padding--md;

  background-color: white;
  border-radius: 3rem;
  box-shadow: $drop-shadow;
  
    svg {
      width: 3rem;
      height: 3rem;
      fill: $color-brand--blue;
    }

    @include mq(screen--sm) {
      width: 10rem;
      height: 10rem;
      padding: 2.5rem;

      border-radius: 5rem;

        svg {
          width: 5rem;
          height: 5rem;
        }
        
    }

}

.hero-img-grid {
  display: none;

    @include mq(screen--sm) {
      position: absolute;
      top: 0;
      left: 60%;
      z-index: 1;

      display: block;
      width: 40%;
      height: 100%;

      overflow: hidden;
    }

}

.hero-img-grid__img-1,
.hero-img-grid__img-2 {
  
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    display: block;
    width: 100%;
    height: 100%;

    border-radius: $border-radius--md;
    overflow: hidden;

    object-fit: cover;
  }

}

.hero-img-grid__img-1 {

  @include mq(screen--sm) {
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: 1;

    width: 20rem;
    padding-bottom: 20rem;

    background-color: rgba(black, 0.1);
  }

  @include mq(screen--lg) {
    width: 30rem;
    padding-bottom: 30rem;
  }

  &:after {
    content: "";

    position: absolute;
    top: calc(100% + 1rem);
    right: 0;

    width: 5rem;
    height: 5rem;

    background-color: white;
    border-radius: $border-radius--md;

      @include mq(screen--lg) {
        width: 10rem;
        height: 10rem;
      }

  }


}

.hero-img-grid__img-2 {

  @include mq(screen--sm) {
    position: absolute;
    bottom: 1rem;
    left: 21rem;
    z-index: 1;

    width: 15rem;
    padding-bottom: 15rem; 

    background-color: rgba(white, 0.25);
  }

  @include mq(screen--lg) {
    left: 31rem;
    
    width: 25rem;
    padding-bottom: 25rem;
  }

}

.hero-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: $padding--lg 0 0;

    @include mq(screen--sm) {
      flex-direction: row;
      align-items: center;
    }

}

.hero-actions__help {
  display: flex;
  margin: $padding--md 0 0;

    @include mq(screen--sm) {
      margin: 0 0 0 $padding--md;
    }

    svg {      
      width: 2rem;
      height: 2rem;
      margin: 0 1rem 0 0;

      fill: white;
    }

    @include ie-support {
      width: 100%;
    }

}

.hero-actions__help__text {
  flex: 1;

  font-size: $font-size--xxs;
  color: white;
  line-height: $line-height--md;
}

// Colour/layout variants

.hero--grey {
  background: $grad-brand--grey;

    .hero-content {
      
      @include mq(screen--sm) {
        width: 50%;
      }

    }

    .hero-content__title {
      color: $color-brand--blue;
    }

    &:after {
      @include top-gradient;
    }
    
}

.hero.promo--blue,
.hero.promo--red {
  color: white;

    .hero-content {
        
      @include mq(screen--sm) {
        max-width: $screen--sm;
      }

    }
    
    .hero-content__text {
      color: white;
    }

    &:after {
      @include top-gradient-promo;
    }

}

// Home hero

.hero--home {
  background: $grad-brand--grey;
  
    .hero-content {
      
      @include mq(screen--sm) {
        width: 75%;
      }

    }

    &:after {
      @include top-gradient;
    }

}

.hero-content__home-content {
  
  @include mq(screen--sm) {
    width: 66.667%;
  }

  .hero-content__sub-title {
    margin: 0 0 $padding--sm;
  
    color: $color-brand--red;
  }

  .hero-content__title {
    display: inline-block;
    padding: 0.5rem;

    line-height: 1;
    color: white;

    background-color: $color-brand--blue;
    border-radius: 0.5rem;
  }

}

.hero-ctas {
  margin: $padding--lg 0 0;
  
  @include mq(screen--xs) {
    display: flex;
    justify-content: space-between;
  }

  .cta-panel {
    
    @include mq(screen--xs) {
      width: 49%;
    }

  }

  .cta-panel + .cta-panel {
    margin: $padding--sm 0 0;

      @include mq(screen--xs) {
        margin: 0;
      }

  }

}

.hero-home-img-grid {
  display: none;

    @include mq(screen--sm) {
      position: absolute;
      top: 0;
      left: 55%;
      z-index: 1;

      display: block;
      width: 50%;
      height: 100%;

      overflow: hidden;
    }

}

.hero-home-img-grid__img-1,
.hero-home-img-grid__img-2,
.hero-home-img-grid__img-3,
.hero-home-img-grid__img-4 {
  
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    display: block;
    width: 100%;
    height: 100%;

    border-radius: $border-radius--md;
    overflow: hidden;

    object-fit: cover;
  }

}

.hero-home-img-grid__img-1 {

  @include mq(screen--sm) {
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: 1;

    width: 30rem;
    padding-bottom: 20rem;

    background-color: rgba(white, 0.25);
  }

  @include mq(screen--xl) {
    width: 50rem;
    padding-bottom: 30rem;
  }

}

.hero-home-img-grid__img-2 {

  @include mq(screen--sm) {
    position: absolute;
    top: 20rem;
    left: 22rem;
    z-index: 1;

    width: 8rem;
    padding-bottom: 8rem;

    background-color: rgba(black, 0.05);
  }

  @include mq(screen--xl) {
    top: 30rem;
    left: 38rem;

    width: 12rem;
    padding-bottom: 12rem;
  }

}

.hero-home-img-grid__img-3 {

  @include mq(screen--sm) {
    position: absolute;
    top: 8rem;
    left: 31rem;
    z-index: 1;

    width: 30rem;
    padding-bottom: 20rem;

    background-color: rgba(white, 0.5);
  }

  @include mq(screen--xl) {
    left: 51rem;

    width: 50rem;
    padding-bottom: 30rem;
  }

}

.hero-home-img-grid__img-4 {
  @include mq(screen--sm) {
    position: absolute;
    top: 29rem;
    left: 31rem;
    z-index: 1;

    width: 12rem;
    padding-bottom: 12rem;

    background-color: rgba(black, 0.1);
  }

  @include mq(screen--xl) {
    top: 39rem;
    left: 51rem;

    width: 20rem;
    padding-bottom: 20rem;
  }

}


