// ====================================
// 404 STYLES
// ====================================

.error-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}

.error-logo {
  margin: 0 0 $padding--lg;
}

.error-logo__img {
  display: block;
  width: 20rem;
  height: auto;
}

.error-text {
  margin: 0 0 $padding--md;

  line-height: $line-height--md;
}

.error-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;

    li {
      margin: 1rem;
    }

    a {
      color: inherit;
      text-decoration: none;

        &:hover {
          color: $color-brand--red;
        }

    }

}