// =================================================
// COOKIES BANNER STYLES
// =================================================

#cookies-banner {
  position: fixed;
  bottom: 5rem;
  left: 10%;
  z-index: 10000;

  width: 80%;
  padding: $padding--md;

  text-align: center;

  background-color: white;
  border-radius: $border-radius--lg;
  box-shadow: $drop-shadow;

    p {
      font-size: $font-size--xxs;
      line-height: $line-height--md;

        @include mq(screen--sm) {
          font-size: $font-size--xs;
        }

        @include mq(screen--lg) {
          font-size: $font-size--sm;
        }

        a {
          color: $color-brand--red;
          text-decoration: underline;

            &:hover {
              color: $color-brand--blue;
            }

        }

    }

}