/*!
Theme Name: Building Heroes Property Services
Theme URI:
Author: Abbas Arezoo
Author URI: http://www.abbasarezoo.com
Description: Building Heroes Property Services Wordpress theme
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/

html,
body {
  border: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  border: none;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

blockquote::before,
blockquote::after {
  content: "";
}

html {
  font-size: 62.5%;
  /* 1rem = 10px */
  scroll-behavior: smooth;
}

body {
  font-family: 'Cabin', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #727272;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  box-shadow: none;
  font-family: inherit;
}

button {
  cursor: pointer;
}

@supports (-ms-accelerator: true) {
  .no-object-fit .object-fit {
    background-size: cover;
    background-position: center;
  }

  .no-object-fit .object-fit img {
    opacity: 0;
    transition: none;
  }
}

.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  transition: all 0s;
}

.tns-slider > .tns-item {
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.3333333%;
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.4285714%;
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.flex-img img {
  display: block;
  width: 100%;
  height: auto;
}

.pg-header {
  position: relative;
  z-index: 10;
}

.pg-header-tel-bar {
  display: none;
}

.pg-header-tel {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: #1d3057;
  text-decoration: none;
}

.pg-header-tel svg {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 0;
  fill: currentColor;
}

.pg-header-tel:hover {
  color: #ba202f;
}

.pg-header-logo {
  position: relative;
  display: block;
  width: 12.5rem;
  margin: 1.25rem auto;
}

.pg-header-logo__img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
}

.poppy-icon {
  position: absolute;
  top: 0;
  right: -2rem;
  z-index: 2;
  width: 2rem;
  height: 2rem;
}

.poppy-icon img {
  display: block;
  width: 100%;
  height: auto;
}

.main-nav-toggle {
  position: absolute;
  top: 0;
  right: 2.5rem;
  z-index: 1;
  display: block;
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  background: none;
}

.main-nav-toggle svg {
  width: 3rem;
  height: 3rem;
  fill: #1d3057;
}

.main-nav-toggle svg:last-child {
  display: none;
}

.main-nav-toggle.is-active svg:first-child {
  display: none;
}

.main-nav-toggle.is-active svg:last-child {
  display: block;
}

.main-nav {
  position: absolute;
  top: 6.5rem;
  right: 0;
  z-index: 1;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  display: none;
  visibility: hidden;
  opacity: 0;
}

.main-nav.is-open {
  display: block;
  visibility: visible;
  opacity: 1;
}

.main-nav__list,
.main-nav__list ul {
  list-style-type: none;
}

.main-nav__list > li > a {
  display: block;
  padding: 1.5rem;
  font-size: 1.8rem;
  color: #727272;
  text-decoration: none;
}

.main-nav__list ul > li > a {
  display: block;
  padding: 1.5rem 1.5rem;
  font-size: 1.8rem;
  color: #727272;
  text-decoration: none;
  border-left: 5px solid #ba202f;
}

.main-nav__list li.current_page_item > a,
.main-nav__list li.current_page_parent > a,
.main-nav__list li.current_page_parent li.current-menu-item > a {
  font-weight: 700;
  text-decoration: underline;
}

.cta-nav {
  display: flex;
}

.cta-nav-btn {
  display: block;
  width: 50%;
  height: 5rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 5rem;
  white-space: nowrap;
  transition: 0.1s ease-out;
}

.cta-nav-btn svg {
  display: none;
}

.cta-nav-btn--blue {
  background-color: #1d3057;
}

.cta-nav-btn--blue:hover {
  background-color: #172544;
}

.cta-nav-btn--red {
  background-color: #ba202f;
}

.cta-nav-btn--red:hover {
  background-color: #a41c2a;
}

.main-nav__list .menu-item-has-children {
  background-color: #f4f4f4;
}

.pg-footer {
  padding: 5rem 0;
  border-top: 1px solid #f4f4f4;
}

.pg-footer__ctas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0 5rem;
}

.pg-footer__ctas .cta-nav {
  width: 100%;
  margin: 2.5rem 0;
}

.pg-footer-nav {
  padding: 0 0 5rem;
  margin: 0 0 5rem;
  border-bottom: 1px solid #f4f4f4;
}

.pg-footer-nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: -1rem;
  list-style-type: none;
}

.pg-footer-nav__list li {
  padding: 1rem;
}

.pg-footer-nav__list a {
  font-size: 1.8rem;
  color: #727272;
  text-decoration: none;
}

.pg-footer-nav__list a:hover {
  color: #ba202f;
}

.pg-footer-signup {
  width: 30rem;
  margin: 0 auto;
}

.copyright {
  margin: 5rem 0 0;
  font-size: 1.4rem;
  color: #1d3057;
}

.outer {
  display: block;
  position: relative;
}

.outer--grey-light {
  background: #f4f4f4;
}

.inner {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 2.5rem;
}

.pg-content__sections {
  margin: 5rem 0;
}

.overlap {
  padding: 5rem 0;
  margin: 0 0 5rem;
  background-color: white;
}

.mt-sm {
  margin-top: 1rem;
}

.mt-md {
  margin-top: 1.25rem;
}

.mt-lg {
  margin-top: 2.5rem;
}

.mt-xl {
  margin-top: 5rem;
}

.mb-sm {
  margin-bottom: 1rem;
}

.mb-md {
  margin-bottom: 1.25rem;
}

.mb-lg {
  margin-bottom: 2.5rem;
}

.mb-xl {
  margin-bottom: 5rem;
}

.mtb-sm {
  margin: 1rem 0;
}

.mtb-md {
  margin: 1.25rem 0;
}

.mtb-lg {
  margin: 2.5rem 0;
}

.mtb-xl {
  margin: 5rem 0;
}

.pt--sm {
  padding-top: 1rem;
}

.pt-md {
  padding-top: 1.25rem;
}

.pt-lg {
  padding-top: 2.5rem;
}

.pt-xl {
  padding-top: 5rem;
}

.pb-sm {
  padding-bottom: 1rem;
}

.pb-md {
  padding-bottom: 1.25rem;
}

.pb-lg {
  padding-bottom: 2.5rem;
}

.pb-xl {
  padding-bottom: 5rem;
}

.ptb-sm {
  padding: 1rem 0;
}

.ptb-md {
  padding: 1.25rem 0;
}

.ptb-lg {
  padding: 2.5rem 0;
}

.ptb-xl {
  padding: 5rem 0;
}

.hero {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.hero > .inner {
  position: relative;
  z-index: 2;
}

.hero-content {
  padding: 5rem 0;
}

.hero-content .btn-group {
  margin: 5rem 0 0;
}

.hero-content__text {
  margin: 1.25rem 0 0;
  line-height: 1.75;
  color: #1d3057;
}

@supports (-ms-accelerator: true) {
  .hero-content__text {
    width: 100%;
  }
}

.hero-content__date {
  display: block;
  margin: 0 0 2.5rem;
  font-weight: 700;
}

.hero-content__title {
  font-size: 2.4rem;
  font-weight: 700;
}

.hero-content__categories {
  padding: 5rem 0 0;
}

.hero-icon {
  width: 6rem;
  height: 6rem;
  padding: 1.5rem;
  margin: 0 0 2.5rem;
  background-color: white;
  border-radius: 3rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.hero-icon svg {
  width: 3rem;
  height: 3rem;
  fill: #1d3057;
}

.hero-img-grid {
  display: none;
}

.hero-img-grid__img-1 img,
.hero-img-grid__img-2 img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
}

.hero-img-grid__img-1:after {
  content: "";
  position: absolute;
  top: calc(100% + 1rem);
  right: 0;
  width: 5rem;
  height: 5rem;
  background-color: white;
  border-radius: 0.5rem;
}

.hero-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5rem 0 0;
}

.hero-actions__help {
  display: flex;
  margin: 2.5rem 0 0;
}

.hero-actions__help svg {
  width: 2rem;
  height: 2rem;
  margin: 0 1rem 0 0;
  fill: white;
}

@supports (-ms-accelerator: true) {
  .hero-actions__help {
    width: 100%;
  }
}

.hero-actions__help__text {
  flex: 1;
  font-size: 1.4rem;
  color: white;
  line-height: 1.5;
}

.hero--grey {
  background: linear-gradient(0deg, #eae7e7 0%, #f4f4f4 100%);
}

.hero--grey .hero-content__title {
  color: #1d3057;
}

.hero--grey:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 1rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.075) 100%);
}

.hero.promo--blue,
.hero.promo--red {
  color: white;
}

.hero.promo--blue .hero-content__text,
.hero.promo--red .hero-content__text {
  color: white;
}

.hero.promo--blue:after,
.hero.promo--red:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 1rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
}

.hero--home {
  background: linear-gradient(0deg, #eae7e7 0%, #f4f4f4 100%);
}

.hero--home:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 1rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.075) 100%);
}

.hero-content__home-content .hero-content__sub-title {
  margin: 0 0 1.25rem;
  color: #ba202f;
}

.hero-content__home-content .hero-content__title {
  display: inline-block;
  padding: 0.5rem;
  line-height: 1;
  color: white;
  background-color: #1d3057;
  border-radius: 0.5rem;
}

.hero-ctas {
  margin: 5rem 0 0;
}

.hero-ctas .cta-panel + .cta-panel {
  margin: 1.25rem 0 0;
}

.hero-home-img-grid {
  display: none;
}

.hero-home-img-grid__img-1 img,
.hero-home-img-grid__img-2 img,
.hero-home-img-grid__img-3 img,
.hero-home-img-grid__img-4 img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
}

.btn-group .btn {
  margin: 0 1rem 1rem 0;
}

.btn-group--align-center {
  justify-content: center;
}

.btn {
  position: relative;
  display: block;
  height: 4rem;
  padding: 0 3.5rem 0 1rem;
  font-family: 'Cabin', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 4rem;
  white-space: nowrap;
  border-radius: 0.5rem;
  transition: 0.1s ease-out;
}

.btn svg {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 1;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  fill: currentColor;
}

.btn--blue {
  background-color: #1d3057;
}

.btn--blue:hover {
  background-color: #172544;
}

.btn--red {
  background-color: #ba202f;
}

.btn--red:hover {
  background-color: #a41c2a;
}

.btn--down svg {
  transform: translateY(-50%) rotate(90deg);
}

.sub-title {
  margin: 0 0 2.5rem;
  font-size: 2.4rem;
  font-weight: 700;
  color: #1d3057;
}

.sub-title--white {
  color: white;
}

.img-gallery {
  margin: 0 0 2.5rem;
}

.img-gallery__nav,
.tradesperson-promo__nav {
  display: flex;
  justify-content: center;
  width: 100%;
  list-style-type: none;
}

.img-gallery__nav li,
.tradesperson-promo__nav li {
  margin: 0 1rem;
}

.img-gallery__nav li.tns-nav-active button,
.tradesperson-promo__nav li.tns-nav-active button {
  background-color: #ba202f;
}

.img-gallery__nav button,
.tradesperson-promo__nav button {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  background-color: #727272;
  border: none;
  border-radius: 100%;
}

.services-grid-outer {
  padding: 5rem 0;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -2.5rem;
  list-style-type: none;
}

.services-grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 2.5rem;
  text-align: center;
}

.services-grid__link {
  display: block;
  text-decoration: none;
}

.services-grid__link:hover .services-grid__icon {
  fill: #ba202f;
}

@supports (-ms-accelerator: true) {
  .services-grid__link {
    width: 100%;
  }
}

.services-grid__icon {
  width: 5rem;
  height: 5rem;
  margin: 0 0 2.5rem;
  fill: #1d3057;
}

.services-grid__title {
  height: 4.5rem;
  margin: 0 0 1.25rem;
  font-weight: 700;
  font-size: 1.8rem;
  color: #1d3057;
  line-height: 1.25;
  text-decoration: none;
}

.services-grid__text {
  font-size: 1.4rem;
  line-height: 1.5;
  color: #1d3057;
  text-decoration: none;
}

.tooltip {
  display: flex;
  padding: 2.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.tooltip__icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1.25rem 0 0;
}

.tooltip__icon svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #ba202f;
}

.tooltip__text {
  flex: 1;
  font-size: 1.4rem;
}

.tooltip__text.std-content p {
  margin: 0;
}

.promo {
  position: relative;
  padding: 5rem 0;
}

.promo > .inner {
  position: relative;
  z-index: 2;
}

.promo .sub-title {
  color: white;
}

.promo .contact-form-gdpr {
  background-color: rgba(255, 255, 255, 0.2);
}

.promo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 1rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
}

.promo__bg {
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: none;
  width: 100%;
  opacity: 0.2;
}

.promo__bg svg {
  width: 100%;
  height: auto;
}

.promo--blue {
  background: linear-gradient(0deg, #121e38 0%, #1d3057 100%);
}

.promo--red {
  background: linear-gradient(0deg, #961a27 0%, #ba202f 100%);
}

.tradesperson-promo-outer {
  display: flex;
  flex-direction: column;
}

.tradesperson-promo {
  order: 2;
  margin: 5rem 0 0;
}

.tradesperson-promo__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 5rem 2.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.tradesperson-promo__info--slide {
  height: 100%;
}

.tradesperson-promo__info--slide .tradesperson-promo__bio {
  min-height: 22rem;
}

.tradesperson-promo__bio-outer {
  text-align: center;
}

@supports (-ms-accelerator: true) {
  .tradesperson-promo__bio {
    width: 30rem;
  }
}

.tradesperson-promo__avatar {
  display: block;
  width: 10rem;
  height: 10rem;
  margin: 0 auto 2.5rem;
  border-radius: 100%;
  overflow: hidden;
}

.tradesperson-promo__avatar img {
  display: block;
  width: 100%;
  height: auto;
}

.tradesperson-promo__name {
  margin: 0 0 2.5rem;
  font-size: 2.1rem;
  color: #1d3057;
}

.tradesperson-promo__bio {
  font-style: italic;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #727272;
}

.testimonial-outer {
  order: 1;
  color: white;
}

.testimonial-outer .std-content h2 {
  color: white;
}

.testimonial-outer .std-content blockquote {
  margin: 2.5rem 0;
  padding: 0;
}

.testimonial-outer .std-content blockquote p {
  font-style: italic;
  font-weight: 700;
  font-size: 1.8rem;
  color: white;
  line-height: 1.75;
  text-align: left;
}

.contact-info-outer {
  margin: 0 0 5rem;
  padding: 0 0 5rem;
  border-bottom: 1px solid #f4f4f4;
}

.contact-info + .contact-info {
  margin: 5rem 0 0;
}

.contact-info-list {
  list-style-type: none;
}

.contact-info-list__item + .contact-info-list__item {
  margin: 2.5rem 0 0;
}

.contact-info-list__info,
.contact-info-list__link {
  display: flex;
}

.contact-info-list__info svg,
.contact-info-list__link svg {
  width: 2rem;
  height: 2rem;
  margin: 0.25rem 2rem 0 0;
}

.contact-info-list__info svg {
  fill: #1d3057;
}

.contact-info-list__link {
  color: #1d3057;
}

.contact-info-list__link:hover {
  color: #ba202f;
}

.contact-info-list__text {
  line-height: 1.5;
}

.pg-footer-social {
  font-size: 1.8rem;
}

.pg-footer-social .contact-info-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pg-footer-social .contact-info-list__link {
  color: #727272;
  text-decoration: none;
}

.pg-footer-social .contact-info-list__link:hover {
  color: #ba202f;
}

.std-content > *:last-child {
  margin-bottom: 0;
}

.std-content h1,
.std-content h2 {
  margin: 0 0 2.5rem;
  font-weight: 700;
  font-size: 3rem;
  color: #1d3057;
  line-height: 1.25;
}

.std-content * + h1,
.std-content * + h2 {
  margin: 5rem 0 2.5rem;
}

.std-content h3,
.std-content h4 {
  margin: 0 0 1.25rem;
  font-size: 2.4rem;
  font-weight: 700;
  color: #1d3057;
  line-height: 1.75;
}

.std-content * + h3,
.std-content * + h4 {
  margin: 5rem 0 1.25rem;
}

.std-content h5,
.std-content h6 {
  margin: 0 0 1.25rem;
  font-size: 2.1rem;
  color: #1d3057;
  line-height: 1.75;
}

.std-content * + h5,
.std-content * + h6 {
  margin: 2.5rem 0 1.25rem;
}

.std-content p a,
.std-content ul a,
.std-content ol a {
  color: #ba202f;
  text-decoration: underline;
}

.std-content p a:hover,
.std-content ul a:hover,
.std-content ol a:hover {
  color: #1d3057;
}

.std-content p strong,
.std-content ul strong,
.std-content ol strong {
  font-weight: 700;
  color: #1d3057;
}

.std-content p em,
.std-content ul em,
.std-content ol em {
  font-style: italic;
}

.std-content p {
  margin: 0 0 2.5rem;
  line-height: 1.75;
}

.std-content sup {
  font-size: 50%;
}

.std-content ul,
.std-content ol {
  margin: 0 0 2.5rem 2.5rem;
}

.std-content li {
  margin: 0 0 0.5rem;
  line-height: 1.75;
}

.std-content ul {
  list-style-type: disc;
}

.std-content ol {
  list-style-type: decimal;
}

.std-content img {
  display: block;
  width: 100%;
  height: auto;
  margin: 5rem 0;
}

.std-content blockquote {
  display: block;
  padding: 1.25rem 2.5rem;
  margin: 5rem 0 2.5rem;
}

.std-content blockquote p {
  margin: 0;
  font-style: italic;
  font-weight: 700;
  font-size: 2.4rem;
  color: #1d3057;
  line-height: 1.75;
  text-align: center;
}

.std-content blockquote p:before {
  content: open-quote;
}

.std-content blockquote p:after {
  content: close-quote;
}

.std-content a {
  color: inherit;
  text-decoration: underline;
}

.std-content table {
  width: 100% !important;
  margin: 0 0 2.5rem;
  border: 1px solid #f4f4f4;
  background-color: white;
}

.std-content table th,
.std-content table td {
  max-width: 30rem;
  padding: 1.5rem 1rem;
  text-align: left;
}

.std-content table tr:last-child td {
  border: none;
}

.std-content table a {
  color: #ba202f;
  text-decoration: underline;
}

.std-content table a:hover {
  color: #1d3057;
}

.std-content hr {
  margin: 2.5rem 0;
  border: 1px solid #f4f4f4;
}

.std-content .video-embed {
  margin: 5rem 0;
}

.wp-admin img.alignleft {
  float: none;
}

.wp-caption {
  width: 100% !important;
  height: auto;
  margin: 5rem 0;
}

.wp-caption img {
  margin: 0;
}

p.wp-caption-text {
  margin: 0;
  padding: 1.25rem;
  color: #1d3057;
  line-height: 1.5;
  background-color: #f4f4f4;
}

.video-embed {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}

.video-embed iframe,
.video-embed object,
.video-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contact-form br {
  height: 0;
}

.contact-form__intro {
  margin: 0 0 5rem;
  padding: 0 0 5rem;
  border-bottom: 1px solid #f4f4f4;
}

.contact-form-section + .contact-form-section {
  margin: 5rem 0 0;
  padding: 5rem 0 0;
  border-top: 1px solid #f4f4f4;
}

.contact-form-marker {
  display: inline-block;
  height: 4rem;
  padding: 0 1rem;
  margin: 0 0 2.5rem;
  font-weight: 700;
  font-size: 1.8rem;
  color: #1d3057;
  line-height: 4rem;
  border-radius: 2.5rem;
  background-color: #8fbb8f;
}

.contact-form-helpers {
  margin: 0 0 2.5rem 2rem;
  font-size: 1.8rem;
  line-height: 1.5;
}

.contact-form-gdpr {
  display: flex;
  padding: 2.5rem;
  border-radius: 0.5rem;
  background-color: #f4f4f4;
}

.contact-form-gdpr label {
  flex: 1;
  font-size: 1.4rem;
  line-height: 1.5;
}

.contact-form-gdpr input {
  margin: 0.5rem 1rem 0 0;
}

.contact-form-gdpr a {
  color: #ba202f;
}

.contact-form-gdpr a:hover {
  color: #1d3057;
}

.contact-form-gdpr .wpcf7-list-item > label {
  display: flex;
}

.contact-form-row + .contact-form-row {
  margin: 2.5rem 0 0;
}

.contact-form-row__label {
  display: block;
  margin: 0 0 1rem;
  font-weight: 700;
  font-size: 1.8rem;
  color: #1d3057;
}

.contact-form-row input[type=text],
.contact-form-row input[type=tel],
.contact-form-row input[type=email],
.contact-form-row input[type=url],
.contact-form-row input[type=password],
.contact-form-fields input[type=text],
.contact-form-fields input[type=tel],
.contact-form-fields input[type=email],
.contact-form-fields input[type=url],
.contact-form-fields input[type=password] {
  width: 100%;
  height: 5rem;
  padding: 0 1rem;
  border: 1px solid #1d3057;
  border-radius: 3px;
  background-color: white;
  font-family: 'Cabin', sans-serif;
  font-size: 1.8rem;
}

.contact-form-row input[type=text].wpcf7-not-valid,
.contact-form-row input[type=tel].wpcf7-not-valid,
.contact-form-row input[type=email].wpcf7-not-valid,
.contact-form-row input[type=url].wpcf7-not-valid,
.contact-form-row input[type=password].wpcf7-not-valid,
.contact-form-fields input[type=text].wpcf7-not-valid,
.contact-form-fields input[type=tel].wpcf7-not-valid,
.contact-form-fields input[type=email].wpcf7-not-valid,
.contact-form-fields input[type=url].wpcf7-not-valid,
.contact-form-fields input[type=password].wpcf7-not-valid {
  border: 3px solid red;
}

.contact-form-row input[type=text]:focus,
.contact-form-row input[type=tel]:focus,
.contact-form-row input[type=email]:focus,
.contact-form-row input[type=url]:focus,
.contact-form-row input[type=password]:focus,
.contact-form-fields input[type=text]:focus,
.contact-form-fields input[type=tel]:focus,
.contact-form-fields input[type=email]:focus,
.contact-form-fields input[type=url]:focus,
.contact-form-fields input[type=password]:focus {
  border: 3px solid #ba202f;
  outline: none;
}

.contact-form-row textarea,
.contact-form-fields textarea {
  width: 100%;
  height: 25rem;
  padding: 1rem;
  border: 1px solid #1d3057;
  border-radius: 3px;
  background-color: white;
  font-family: 'Cabin', sans-serif;
  font-size: 1.8rem;
}

.contact-form-row textarea.wpcf7-not-valid,
.contact-form-fields textarea.wpcf7-not-valid {
  border: 3px solid red;
}

.contact-form-row textarea:focus,
.contact-form-fields textarea:focus {
  border: 3px solid #ba202f;
  outline: none;
}

.contact-form-row select,
.contact-form-fields select {
  width: 100%;
  height: 5rem;
  border: 1px solid #1d3057;
  background-color: white;
  font-size: 1.8rem;
}

.submit-btn {
  position: relative;
  background-color: #ba202f;
  border-radius: 0.5rem;
  overflow: hidden;
}

.submit-btn:hover {
  background-color: #a41c2a;
}

.submit-btn input[type="submit"] {
  position: relative;
  z-index: 2;
  height: 5rem;
  padding: 0 5rem 0 1rem;
  font-family: 'Cabin', sans-serif;
  font-size: 2.1rem;
  font-weight: 700;
  color: white;
  line-height: 5rem;
  border: none;
  transition: 0.1s ease-out;
  background: none;
  cursor: pointer;
}

.submit-btn svg {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}

.contact-form-checkboxes,
.contact-form-services > span {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 2.5rem;
}

.contact-form-checkbox,
.contact-form-services .wpcf7-list-item {
  margin: 0 1rem 1rem 0;
}

.contact-form-checkbox > input[type="checkbox"] {
  display: none;
}

.contact-form-checkbox > label {
  display: block;
  height: 4rem;
  padding: 0 1rem;
  font-weight: 700;
  font-size: 1.8rem;
  color: #1d3057;
  line-height: calc(4rem - 4px);
  border: 2px solid #727272;
  border-radius: 2rem;
}

.contact-form-checkbox > label:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.contact-form-checkbox > input[type="checkbox"]:checked ~ label {
  background-color: #1d3057;
  border-color: #1d3057;
  color: white;
}

.contact-form-radios {
  margin: 0 0 5rem;
}

.contact-form-radios__label,
.contact-form-radio,
.contact-form-graduate label {
  display: block;
  font-weight: 700;
  color: #1d3057;
}

.contact-form-radios__label {
  margin: 0 0 2.5rem;
}

.contact-form-radio + .contact-form-radio,
.contact-form-graduate span.wpcf7-list-item + span.wpcf7-list-item {
  margin: 2.5rem 0 0;
}

.contact-form-graduate span.wpcf7-list-item {
  display: block;
}

.contact-form-graduate span.wpcf7-list-item label {
  display: flex;
}

.contact-form-graduate span.wpcf7-list-item input {
  margin: 0.5rem 1rem 0 0;
}

.contact-form-graduate span.wpcf7-list-item .wpcf7-list-item-label {
  flex: 1;
  line-height: 1.5;
}

.contact-form-actions {
  display: flex;
  margin: 2.5rem 0 0;
}

.next-step {
  font-family: 'Cabin', sans-serif;
}

.next-step svg {
  transform: translateY(-50%) rotate(90deg);
}

.previous-step {
  margin: 0 0 0 2rem;
  border: none;
  background: none;
  font-family: 'Cabin', sans-serif;
  font-size: 1.8rem;
  color: #727272;
  text-decoration: none;
}

.previous-step:hover {
  color: #ba202f;
  text-decoration: underline;
}

.not-valid {
  display: block;
  padding: 1.25rem;
  margin: 1rem 0 0;
  background-color: red;
  font-size: 1.8rem;
  color: white;
}

form.wpcf7-form.sent,
form.wpcf7-form.invalid {
  padding: 0 0 5rem;
}

div.wpcf7 .ajax-loader {
  display: none !important;
}

.screen-reader-response {
  display: none;
}

span.wpcf7-not-valid-tip {
  display: block;
  padding: 1.25rem;
  margin: 1rem 0 0;
  background-color: red;
  border-radius: 3px;
  font-size: 1.8rem;
  color: white;
}

div.wpcf7-validation-errors,
div.wpcf7-mail-sent-ng,
div.wpcf7-mail-sent-ok {
  border: none !important;
}

div.wpcf7-response-output {
  margin: 0 !important;
  padding: 0;
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  border-radius: 3px;
}

div.wpcf7-response-output.wpcf7-validation-errors {
  margin: 2.5rem 0 0 !important;
  padding: 1.25rem;
  background-color: red;
}

div.wpcf7-response-output.wpcf7-validation-success,
div.wpcf7-response-output.wpcf7-mail-sent-ok {
  margin: 2.5rem 0 0 !important;
  padding: 1.25rem;
  background-color: #00c900;
}

.wpcf7-form.invalid div.wpcf7-response-output,
.wpcf7-form.spam div.wpcf7-response-output,
.wpcf7-form.success div.wpcf7-response-output,
.wpcf7-form.sent div.wpcf7-response-output {
  margin: 2.5rem 0 0 !important;
  padding: 1.25rem;
  border-radius: 3px;
}

.wpcf7-form.invalid div.wpcf7-response-output,
.wpcf7-form.spam div.wpcf7-response-output {
  background-color: red;
}

.wpcf7-form.sent div.wpcf7-response-output,
.wpcf7-form.success div.wpcf7-response-output {
  background-color: #00c900;
}

.promo--blue .contact-form-helper,
.promo--red .contact-form-helper {
  color: white;
}

.promo--blue .contact-form-row__label,
.promo--red .contact-form-row__label {
  color: white;
}

.promo--blue .contact-form-checkbox > label,
.promo--red .contact-form-checkbox > label {
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.promo--blue .contact-form-checkbox > label:hover,
.promo--red .contact-form-checkbox > label:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.promo--blue .contact-form-checkbox > input[type="checkbox"]:checked ~ label,
.promo--red .contact-form-checkbox > input[type="checkbox"]:checked ~ label {
  background-color: #ba202f;
  border-color: #ba202f;
}

.promo--blue .contact-form-gdpr label,
.promo--red .contact-form-gdpr label {
  color: white;
}

.promo--blue .contact-form-gdpr label a,
.promo--red .contact-form-gdpr label a {
  color: white;
}

.promo--blue .previous-step,
.promo--red .previous-step {
  color: white;
}

.overlap .contact-form {
  padding: 0 2.5rem;
}

.contact-form-services .wpcf7-checkbox input[type=checkbox] {
  position: absolute;
  visibility: hidden;
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
}

.contact-form-services .wpcf7-checkbox input[type=checkbox] + span {
  display: block;
  height: 4rem;
  padding: 0 1rem;
  font-weight: 700;
  font-size: 1.8rem;
  color: #1d3057;
  line-height: calc(4rem - 4px);
  border: 2px solid #727272;
  border-radius: 2rem;
}

.contact-form-services .wpcf7-checkbox input[type=checkbox] + span:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.contact-form-services .wpcf7-checkbox input[type=checkbox]:checked + span {
  background-color: #1d3057;
  border-color: #1d3057;
  color: white;
}

.cta-panel {
  display: flex;
  padding: 2.5rem 1.25rem;
  border-radius: 0.5rem;
  color: white;
}

.cta-panel__icon {
  width: 4rem;
  height: 4rem;
  margin: 0 1.25rem 0 0;
  fill: white;
}

.cta-panel__content {
  flex: 1;
}

.cta-panel__title {
  margin: 0 0 1.25rem;
}

.cta-panel__text {
  margin: 0 0 1.25rem;
  font-size: 1.4rem;
  line-height: 1.5;
}

.cta-panel__link {
  margin: 1.25rem 0 0;
  font-size: 1.8rem;
  color: inherit;
}

.cta-panel__link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.cta-panel--blue {
  background: linear-gradient(0deg, #121e38 0%, #1d3057 100%);
}

.cta-panel--red {
  background: linear-gradient(0deg, #961a27 0%, #ba202f 100%);
}

.hero-ctas .cta-panel__text {
  display: none;
}

.promo .cta-panel {
  display: none;
}

.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.5rem;
}

.how-it-works__step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
  padding: 2.5rem;
}

.how-it-works__icons {
  position: relative;
  margin: 0 0 2.5rem;
}

.how-it-works__marker {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: block;
  width: 3rem;
  height: 3rem;
  font-size: 2.1rem;
  font-weight: 700;
  color: #1d3057;
  text-align: center;
  line-height: 3rem;
  background-color: #8fbb8f;
  border-radius: 1.5rem;
}

.how-it-works__icon-1 {
  position: relative;
  z-index: 1;
  width: 10rem;
  height: 10rem;
  margin: 1.5rem 0 2rem;
  padding: 2.5rem;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.how-it-works__icon-1 svg {
  width: 5rem;
  height: 5rem;
  fill: #1d3057;
}

.how-it-works__icon-2 {
  position: absolute;
  bottom: 0;
  right: -5rem;
  z-index: 2;
  width: 7rem;
  height: 7rem;
  padding: 2rem;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.how-it-works__icon-2 svg {
  width: 3rem;
  height: 3rem;
  fill: #ba202f;
}

.how-it-works__text {
  font-size: 1.8rem;
  line-height: 1.5;
  text-align: center;
}

.how-it-works__text a {
  color: #ba202f;
}

.how-it-works__text a:hover {
  color: #1d3057;
}

@supports (-ms-accelerator: true) {
  .how-it-works__text {
    display: block;
    width: 100%;
  }
}

.filter-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -0.5rem;
  list-style-type: none;
}

.filter-list__item {
  padding: 0.5rem;
}

.filter-list__filter {
  display: block;
  height: 4rem;
  padding: 0 1rem;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.8rem;
  color: #1d3057;
  line-height: calc(4rem - 4px);
  border: 2px solid #727272;
  border-radius: 2rem;
}

.filter-list__filter:hover {
  background-color: white;
  cursor: pointer;
}

.filter-list__filter.mixitup-control-active {
  border-color: #ba202f;
  background-color: #ba202f;
  color: white;
}

.hero .filter-list {
  margin: 5rem 0 0;
}

.posts-feed {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin: -1rem;
}

.posts-feed-item-outer {
  width: 30rem;
  padding: 1rem;
}

.posts-feed-item {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.posts-feed-item__link {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 66.66667%;
}

.posts-feed-item__link__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.posts-feed-item__content {
  padding: 2.5rem;
}

.posts-feed-item__cats,
.hero-content__categories {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  list-style-type: none;
}

.posts-feed-item__cats li,
.hero-content__categories li {
  padding: 0.5rem;
}

.posts-feed-item__cat,
.hero-content__category {
  display: block;
  height: 3rem;
  padding: 0 0.75rem;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.4rem;
  color: white;
  line-height: 3rem;
  border-radius: 1.5rem;
  background-color: #ba202f;
}

.posts-feed-item__title {
  display: block;
  margin: 2.5rem 0;
  line-height: 1.5;
  color: #1d3057;
  text-decoration: none;
}

.posts-feed-item__title:hover {
  color: #ba202f;
  text-decoration: underline;
}

.posts-feed-item__date {
  font-size: 1.4rem;
}

.related-posts {
  margin: 5rem 0 0;
  padding: 5rem 0 0;
  border-top: 1px solid #f4f4f4;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 5rem 0 0;
}

.pagination button {
  margin: 0.5rem;
}

.pagination__pg-number {
  display: block;
  width: 3rem;
  height: 3rem;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.4rem;
  color: #1d3057;
  line-height: calc(3rem - 4px);
  text-align: center;
  border: 2px solid #727272;
  border-radius: 2rem;
}

.pagination__pg-number:hover {
  background-color: white;
  cursor: pointer;
}

.pagination__pg-number.mixitup-control-active {
  border-color: #1d3057;
  background-color: #1d3057;
  color: white;
}

.pagination__pg-prev,
.pagination__pg-next {
  display: block;
  width: 3rem;
  height: 3rem;
  padding: 0;
  border: 0;
  background-color: #ba202f;
  border-radius: 1.5rem;
}

.pagination__pg-prev svg,
.pagination__pg-next svg {
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
  fill: white;
}

.pagination__pg-prev:hover,
.pagination__pg-next:hover {
  background-color: #a41c2a;
}

.pagination__pg-prev svg {
  transform: rotate(-180deg);
}

.social-list {
  list-style-type: none;
}

.social-list__link {
  display: flex;
  align-items: center;
  color: #727272;
  text-decoration: none;
}

.social-list__link svg {
  width: 2rem;
  height: 2rem;
  margin: 0 1rem 0 0;
}

.social-list__link:hover {
  color: #ba202f;
}

.pg-footer-social .social-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: -1rem;
}

.pg-footer-social .social-list__item {
  padding: 1rem;
  font-size: 1.8rem;
}

.contact-info .social-list {
  margin: -1rem;
}

.contact-info .social-list__item {
  padding: 1rem;
}

.update-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  padding: 1.25rem 4rem 1.25rem 1.25rem;
  background-color: #1d3057;
}

.update-banner__text {
  font-size: 1.4rem;
  line-height: 1.5;
  color: white;
}

.close-update-banner {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
  display: block;
  width: 4rem;
  height: 4rem;
  padding: 0 1.25rem;
  background: none;
}

.close-update-banner svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}

.newsletter-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 5rem 0 0;
}

.newsletter-signup label {
  display: block;
  margin: 0 0 1.25rem;
  font-size: 1.8rem;
}

.newsletter-signup__form {
  display: flex;
  width: 100%;
}

input[type="email"].newsletter-signup__field {
  flex: 1;
  height: 5rem;
  padding: 0 1rem;
  border: 1px solid #727272;
  border-radius: 0.5rem 0 0 0.5rem;
  font-size: 1.8rem;
}

.newsletter-signup__submit-btn {
  position: relative;
  width: 5rem;
  height: 5rem;
  background-color: #ba202f;
  border-radius: 0 0.5rem 0.5rem 0;
  overflow: hidden;
}

.newsletter-signup__submit-btn:hover {
  background-color: #a41c2a;
}

.newsletter-signup__submit-btn input[type="submit"] {
  position: relative;
  z-index: 2;
  width: 5rem;
  height: 5rem;
  border: none;
  transition: 0.1s ease-out;
  background: none;
  cursor: pointer;
}

.newsletter-signup__submit-btn svg {
  position: absolute;
  top: 1.75rem;
  right: 1.75rem;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}

.response {
  margin: 1.25rem 0 0;
  padding: 1.25rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  color: white;
}

.response a {
  color: inherit;
}

.response--fail {
  background-color: #ba202f;
}

.response--success {
  background-color: #1d3057;
}

.custom-table {
  position: relative;
  font-size: 1.4rem;
  overflow: auto;
  border: 2px solid #1d3057;
}

.custom-table table {
  position: relative;
  z-index: 1;
  width: 768px;
  table-layout: fixed;
}

.custom-table th,
.custom-table td {
  text-align: left;
  line-height: 1.5;
}

.custom-table th {
  padding: 1.5rem;
  color: white;
  background-color: #1d3057;
}

.custom-table td {
  padding: 2rem;
}

.custom-table tr > td:not(:last-child) {
  border-right: 1px solid #f4f4f4;
}

.custom-table tr:not(:last-child) > td {
  border-bottom: 1px solid #f4f4f4;
}

.custom-table__helper {
  display: inline-block;
  padding: 1rem;
  margin: 2.5rem 0 0;
  font-size: 1.4rem;
  color: #ba202f;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

#cookies-banner {
  position: fixed;
  bottom: 5rem;
  left: 10%;
  z-index: 10000;
  width: 80%;
  padding: 2.5rem;
  text-align: center;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

#cookies-banner p {
  font-size: 1.4rem;
  line-height: 1.5;
}

#cookies-banner p a {
  color: #ba202f;
  text-decoration: underline;
}

#cookies-banner p a:hover {
  color: #1d3057;
}

.error-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.error-logo {
  margin: 0 0 5rem;
}

.error-logo__img {
  display: block;
  width: 20rem;
  height: auto;
}

.error-text {
  margin: 0 0 2.5rem;
  line-height: 1.5;
}

.error-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
}

.error-list li {
  margin: 1rem;
}

.error-list a {
  color: inherit;
  text-decoration: none;
}

.error-list a:hover {
  color: #ba202f;
}

@media (min-width: 450px) {
  .tradesperson-promo__info {
    width: 40rem;
  }
}

@media (min-width: 650px) {
  .pg-footer-nav__list {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .hero-ctas {
    display: flex;
    justify-content: space-between;
  }

  .hero-ctas .cta-panel {
    width: 49%;
  }

  .hero-ctas .cta-panel + .cta-panel {
    margin: 0;
  }

  .pg-footer-social .contact-info-list {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }

  img.alignleft {
    width: 33.33333%;
    float: left;
    margin: 0 5rem 5rem 0;
  }

  img.alignright {
    width: 33.33333%;
    float: right;
    margin: 0 0 5rem 5rem;
  }

  .posts-feed {
    justify-content: flex-start;
  }

  .posts-feed-item-outer {
    width: 50%;
  }

  .pg-footer-social .social-list {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .update-banner {
    padding: 1.25rem 4rem;
  }
}

@media (min-width: 768px) {
  .pg-header {
    margin: 0 0 2.5rem;
    padding: 0 5rem;
  }

  .pg-header-tel-bar {
    display: flex;
    justify-content: flex-end;
    padding: 1.25rem 0;
  }

  .pg-header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem 0 0;
  }

  .pg-header-logo {
    width: 15rem;
    margin: 0;
  }

  .main-nav-toggle {
    top: 5.25rem;
    right: 5rem;
  }

  .main-nav {
    top: 11.5rem;
    width: 50%;
  }

  .cta-nav-btn {
    width: auto;
    height: 4rem;
    padding: 0 1rem;
    line-height: 4rem;
    border-radius: 0.5rem;
  }

  .cta-nav-btn--red {
    margin: 0 0 0 1rem;
  }

  .pg-footer {
    padding: 10rem 5rem;
  }

  .pg-footer__ctas {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10rem;
  }

  .pg-footer__ctas .cta-nav {
    width: auto;
    margin: 0;
  }

  .inner {
    padding: 0 5rem;
  }

  .inner--sm {
    width: 768px;
  }

  .hero {
    min-height: 30rem;
  }

  .hero-content--centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }

  .hero-content__title {
    font-size: 3rem;
  }

  .hero-icon {
    width: 10rem;
    height: 10rem;
    padding: 2.5rem;
    border-radius: 5rem;
  }

  .hero-icon svg {
    width: 5rem;
    height: 5rem;
  }

  .hero-img-grid {
    position: absolute;
    top: 0;
    left: 60%;
    z-index: 1;
    display: block;
    width: 40%;
    height: 100%;
    overflow: hidden;
  }

  .hero-img-grid__img-1 {
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: 1;
    width: 20rem;
    padding-bottom: 20rem;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .hero-img-grid__img-2 {
    position: absolute;
    bottom: 1rem;
    left: 21rem;
    z-index: 1;
    width: 15rem;
    padding-bottom: 15rem;
    background-color: rgba(255, 255, 255, 0.25);
  }

  .hero-actions {
    flex-direction: row;
    align-items: center;
  }

  .hero-actions__help {
    margin: 0 0 0 2.5rem;
  }

  .hero--grey .hero-content {
    width: 50%;
  }

  .hero.promo--blue .hero-content,
  .hero.promo--red .hero-content {
    max-width: 768px;
  }

  .hero--home .hero-content {
    width: 75%;
  }

  .hero-content__home-content {
    width: 66.667%;
  }

  .hero-home-img-grid {
    position: absolute;
    top: 0;
    left: 55%;
    z-index: 1;
    display: block;
    width: 50%;
    height: 100%;
    overflow: hidden;
  }

  .hero-home-img-grid__img-1 {
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: 1;
    width: 30rem;
    padding-bottom: 20rem;
    background-color: rgba(255, 255, 255, 0.25);
  }

  .hero-home-img-grid__img-2 {
    position: absolute;
    top: 20rem;
    left: 22rem;
    z-index: 1;
    width: 8rem;
    padding-bottom: 8rem;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .hero-home-img-grid__img-3 {
    position: absolute;
    top: 8rem;
    left: 31rem;
    z-index: 1;
    width: 30rem;
    padding-bottom: 20rem;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hero-home-img-grid__img-4 {
    position: absolute;
    top: 29rem;
    left: 31rem;
    z-index: 1;
    width: 12rem;
    padding-bottom: 12rem;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .sub-title--lg {
    margin: 0 0 5rem;
  }

  .services-grid__item {
    width: 33.333%;
  }

  .tooltip__icon {
    width: 4rem;
    height: 4rem;
    margin: 0 2.5rem 0 0;
  }

  .tooltip__icon svg {
    width: 4rem;
    height: 4rem;
  }

  .tooltip__text {
    font-size: 1.8rem;
  }

  .promo {
    padding: 10rem 0;
  }

  .promo__bg {
    display: block;
  }

  .testimonial-outer {
    width: 60rem;
    margin: 0 auto;
  }

  img.alignleft {
    width: 25%;
  }

  img.alignright {
    width: 25%;
  }

  p.wp-caption-text {
    padding: 2.5rem 1.25rem;
  }

  .contact-form-marker {
    margin: 0 0 5rem;
  }

  .contact-form-helpers {
    margin: 0 0 5rem 2rem;
  }

  .contact-form-row + .contact-form-row {
    margin: 3rem 0 0;
  }

  .contact-form-row input[type=text],
  .contact-form-row input[type=tel],
  .contact-form-row input[type=email],
  .contact-form-row input[type=url],
  .contact-form-row input[type=password],
  .contact-form-fields input[type=text],
  .contact-form-fields input[type=tel],
  .contact-form-fields input[type=email],
  .contact-form-fields input[type=url],
  .contact-form-fields input[type=password] {
    height: 6rem;
  }

  .contact-form-row textarea,
  .contact-form-fields textarea {
    height: 30rem;
    padding: 2rem 1rem;
  }

  .submit-btn input[type="submit"] {
    height: 6rem;
    padding: 0 6rem 0 1.5rem;
  }

  .submit-btn svg {
    width: 2rem;
    height: 2rem;
    right: 2rem;
  }

  .contact-form-checkboxes,
  .contact-form-services > span {
    margin: 0 0 5rem;
  }

  .contact-form-checkbox > label {
    height: 5rem;
    padding: 0 2rem;
    font-size: 2.1rem;
    line-height: calc(5rem - 4px);
    border-radius: 2.5rem;
  }

  .contact-form-actions {
    margin: 5rem 0 0;
  }

  .overlap .contact-form {
    max-width: 768px;
    margin: 0 auto;
    padding: 0 5rem;
  }

  .contact-form-services .wpcf7-checkbox input[type=checkbox] + span {
    height: 5rem;
    padding: 0 2rem;
    font-size: 2.1rem;
    line-height: calc(5rem - 4px);
    border-radius: 2.5rem;
  }

  .promo .cta-panel {
    display: flex;
    width: 35%;
    position: absolute;
    top: 5rem;
    right: 2.5rem;
    z-index: 1;
  }

  .how-it-works {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 5rem;
  }

  .how-it-works__step {
    width: 33.33333%;
  }

  .custom-table table {
    width: 100%;
  }

  .custom-table__helper {
    display: none;
  }

  #cookies-banner p {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .pg-footer__links {
    display: flex;
  }

  .pg-footer-nav {
    width: 50%;
    padding: 0 5rem 0 0;
    margin: 0;
    border-bottom: none;
    border-right: 1px solid #f4f4f4;
  }

  .pg-footer-nav__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .pg-footer-nav__list li {
    width: 50%;
  }

  .pg-footer-social {
    width: 25%;
    padding: 0 5rem;
  }

  .pg-footer-signup {
    width: 25%;
  }

  .inner--md {
    width: 1024px;
  }

  .tradesperson-promo-outer {
    flex-direction: row;
    align-items: center;
  }

  .tradesperson-promo {
    order: 1;
    margin: 0;
  }

  .tradesperson-promo__bio {
    font-size: 1.8rem;
  }

  .testimonial-outer {
    order: 2;
    flex: 1;
    width: auto;
    margin: 2.5rem 0 0 5rem;
  }

  .pg-footer-social .contact-info-list {
    align-items: flex-start;
  }

  .inner--lg .contact-form-section {
    width: 768px;
  }

  .posts-feed-item-outer {
    width: 33.33333%;
  }

  .pg-footer-social .social-list {
    flex-direction: column;
    justify-content: flex-start;
  }

  .newsletter-signup {
    align-items: flex-start;
    margin: 0;
  }
}

@media (min-width: 1280px) {
  body {
    font-size: 2.1rem;
  }

  .pg-header {
    width: 1280px;
    margin: 0 auto 2.5rem;
  }

  .pg-header-nav {
    padding: 0;
  }

  .main-nav-toggle {
    display: none;
  }

  .main-nav {
    position: relative;
    top: 0;
    display: block;
    padding: 0 5rem 0 0;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    flex: 1;
  }

  .main-nav__list {
    display: flex;
    justify-content: flex-end;
  }

  .main-nav__list > li {
    position: relative;
    padding: 2.5rem 0 2.5rem 5rem;
  }

  .main-nav__list > li:hover > a {
    color: #ba202f;
  }

  .main-nav__list > li:hover > ul {
    display: block;
    visibility: visible;
    opacity: 1;
  }

  .main-nav__list > li > a {
    padding: 0;
  }

  .main-nav__list > li > a:hover {
    color: #ba202f;
  }

  .main-nav__list ul {
    position: absolute;
    top: 6rem;
    z-index: 10000;
    display: none;
    visibility: hidden;
    opacity: 0;
    width: 30rem;
    padding: 0.5rem 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    background-color: white;
    transition: 0.1s ease-out;
  }

  .main-nav__list ul > li > a {
    padding: 1rem 1.5rem;
    border: none;
  }

  .main-nav__list ul > li > a:hover {
    color: #ba202f;
  }

  .main-nav__list ul > li:first-child > a {
    font-weight: 700;
    color: #1d3057;
  }

  .cta-nav-btn {
    position: relative;
    display: block;
    padding: 0 3.5rem 0 1rem;
    font-size: 1.8rem;
  }

  .cta-nav-btn svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    z-index: 1;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0 0 1rem;
    fill: currentColor;
  }

  .main-nav__list .menu-item-has-children {
    background: none;
  }

  .pg-footer {
    width: 1280px;
    margin: 0 auto 2.5rem;
  }

  .inner--lg {
    width: 1280px;
  }

  .pg-content__sections {
    margin: 10rem 0;
  }

  .overlap {
    position: relative;
    z-index: 2;
    max-width: calc(1280px - 10rem);
    margin: 0 auto;
    padding: 10rem 0;
    transform: translateY(-10rem);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }

  .with-overlap {
    padding: 0 0 10rem;
  }

  .mt-sm {
    margin-top: 1.25rem;
  }

  .mt-md {
    margin-top: 2.5rem;
  }

  .mt-lg {
    margin-top: 5rem;
  }

  .mt-xl {
    margin-top: 10rem;
  }

  .mb-sm {
    margin-bottom: 1.25rem;
  }

  .mb-md {
    margin-bottom: 2.5rem;
  }

  .mb-lg {
    margin-bottom: 5rem;
  }

  .mb-xl {
    margin-bottom: 10rem;
  }

  .mtb-sm {
    margin: 1.25rem 0;
  }

  .mtb-md {
    margin: 2.5rem 0;
  }

  .mtb-lg {
    margin: 5rem 0;
  }

  .mtb-xl {
    margin: 10rem 0;
  }

  .pt--sm {
    padding-top: 1.25rem;
  }

  .pt-md {
    padding-top: 2.5rem;
  }

  .pt-lg {
    padding-top: 5rem;
  }

  .pt-xl {
    padding-top: 10rem;
  }

  .pb-sm {
    padding-bottom: 1.25rem;
  }

  .pb-md {
    padding-bottom: 2.5rem;
  }

  .pb-lg {
    padding-bottom: 5rem;
  }

  .pb-xl {
    padding-bottom: 10rem;
  }

  .ptb-sm {
    padding: 1.25rem 0;
  }

  .ptb-md {
    padding: 2.5rem 0;
  }

  .ptb-lg {
    padding: 5rem 0;
  }

  .ptb-xl {
    padding: 10rem 0;
  }

  .hero {
    min-height: 45rem;
  }

  .hero-content {
    padding: 10rem 0;
  }

  .hero-content__text {
    margin: 2.5rem 0 0;
  }

  .hero-content__title {
    font-size: 4rem;
  }

  .hero-img-grid__img-1 {
    width: 30rem;
    padding-bottom: 30rem;
  }

  .hero-img-grid__img-1:after {
    width: 10rem;
    height: 10rem;
  }

  .hero-img-grid__img-2 {
    left: 31rem;
    width: 25rem;
    padding-bottom: 25rem;
  }

  .sub-title {
    font-size: 3rem;
  }

  .services-grid-outer {
    padding: 10rem 0;
  }

  .services-grid__item {
    width: 20%;
  }

  .services-grid__icon {
    width: 8rem;
    height: 8rem;
    margin: 0 0 5rem;
  }

  .services-grid__title {
    margin: 0 0 2.5rem;
    font-size: 2.1rem;
  }

  .promo.with-overlap {
    padding: 10rem 0 20rem;
  }

  .std-content table {
    margin: 0 0 5rem;
  }

  .std-content hr {
    margin: 5rem 0;
  }

  .contact-form-row__label {
    font-size: 2.1rem;
  }

  .contact-form-graduate span.wpcf7-list-item input {
    margin: 1rem 1rem 0 0;
  }

  .cta-panel {
    padding: 2.5rem 2.5rem 2.5rem 1.25rem;
  }

  .hero-ctas .cta-panel__text {
    display: block;
  }

  .promo .cta-panel {
    right: 5rem;
    width: 25%;
  }

  .how-it-works__icon-1 {
    width: 12rem;
    height: 12rem;
    padding: 3rem;
  }

  .how-it-works__icon-1 svg {
    width: 6rem;
    height: 6rem;
  }

  .how-it-works__icon-2 {
    right: -6rem;
    width: 8rem;
    height: 8rem;
  }

  .how-it-works__icon-2 svg {
    width: 4rem;
    height: 4rem;
  }

  .related-posts {
    margin: 10rem 0 0;
    padding: 10rem 0 0;
  }

  .pagination {
    margin: 10rem 0 0;
  }

  .custom-table {
    font-size: 1.8rem;
  }

  #cookies-banner p {
    font-size: 2.1rem;
  }
}

@media (min-width: 1600px) {
  .inner--xl {
    width: 1600px;
  }

  .hero-home-img-grid__img-1 {
    width: 50rem;
    padding-bottom: 30rem;
  }

  .hero-home-img-grid__img-2 {
    top: 30rem;
    left: 38rem;
    width: 12rem;
    padding-bottom: 12rem;
  }

  .hero-home-img-grid__img-3 {
    left: 51rem;
    width: 50rem;
    padding-bottom: 30rem;
  }

  .hero-home-img-grid__img-4 {
    top: 39rem;
    left: 51rem;
    width: 20rem;
    padding-bottom: 20rem;
  }
}

@media (min-width: 1800px) {
  .inner--xxl {
    width: 1800px;
  }
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  * {
    -webkit-animation-duration: 0s !important;
    animation-duration: 0s !important;
    transition-duration: 0s !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .no-object-fit .object-fit {
    background-size: cover;
    background-position: center;
  }

  .no-object-fit .object-fit img {
    opacity: 0;
    transition: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hero-content__text {
    width: 100%;
  }

  .hero-actions__help {
    width: 100%;
  }

  .services-grid__link {
    width: 100%;
  }

  .tradesperson-promo__bio {
    width: 30rem;
  }

  .how-it-works__text {
    display: block;
    width: 100%;
  }
}