// =================================================
// FEED STYLES
// =================================================

.posts-feed {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;

  margin: -1rem;

    @include mq(screen--xs) {
      justify-content: flex-start;
    }

}

.posts-feed-item-outer {
  width: 30rem;
  padding: 1rem;

    @include mq(screen--xs) {
      width: 50%;
    }

    @include mq(screen--md) {
      width: percentage(1/3);
    }

}

.posts-feed-item {
  box-shadow: $drop-shadow;
}

.posts-feed-item__link {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: percentage(4/6);
}

.posts-feed-item__link__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.posts-feed-item__content {
  padding: $padding--md;
}

.posts-feed-item__cats {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;

  list-style-type: none;

    li {
      padding: 0.5rem;
    }

}
  
.posts-feed-item__cat {
  display: block;
  height: 3rem;
  padding: 0 0.75rem;

  font-family: inherit;
  font-weight: $font-weight--bold;
  font-size: $font-size--xxs;
  color: white;
  line-height: 3rem;

  border-radius: 1.5rem;
  background-color: $color-brand--red;
}

.posts-feed-item__title {
  display: block;
  margin: $padding--md 0;

  line-height: $line-height--md;
  color: $color-brand--blue;
  text-decoration: none;

    &:hover {
      color: $color-brand--red;
      text-decoration: underline;
    }

}

.posts-feed-item__date {
  font-size: $font-size--xxs;
}

// Related

.related-posts {
  margin: $padding--lg 0 0;
  padding: $padding--lg 0 0;

  border-top: 1px solid $color-ui--grey-light;

    @include mq(screen--lg) {
      margin: $padding--xl 0 0;
      padding: $padding--xl 0 0;
    }

}
