// ====================================
// TRADESPERSON PROMO STYLES
// ====================================

.tradesperson-promo-outer {
  display: flex;
  flex-direction: column;
  
  @include mq(screen--md) {
    flex-direction: row;
    align-items: center;
  }

}

.tradesperson-promo {
  order: 2;
  margin: $padding--lg 0 0;

    @include mq(screen--md) {
      order: 1;
      margin: 0;
    }
    
}

.tradesperson-promo__info {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  padding: $padding--lg $padding--md;

  background-color: white;
  border-radius: $border-radius--md;
  box-shadow: $drop-shadow;

    @include mq(screen--xxs) {
      width: 40rem;
    }

}

.tradesperson-promo__info--slide {
  height: 100%;

    .tradesperson-promo__bio {
      min-height: 22rem;
    }
    
}

.tradesperson-promo__bio-outer {
  text-align: center;
}

.tradesperson-promo__bio {
  
  @include ie-support {
    width: 30rem;
  }

}

.tradesperson-promo__avatar {
  display: block;
  width: 10rem;
  height: 10rem;
  margin: 0 auto $padding--md;

  border-radius: 100%;
  overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

}

.tradesperson-promo__name {
  margin: 0 0 $padding--md;

  font-size: $font-size--sm;
  color: $color-brand--blue;
}

.tradesperson-promo__bio {  
  font-style: italic;
  font-size: $font-size--xxs;
  line-height: $line-height--md;
  color: $color-ui--grey-mid;

    @include mq(screen--md) {
      font-size: $font-size--xs;
    }

}

.testimonial-outer {
  order: 1;
  color: white;

    @include mq(screen--sm) {
      width: 60rem;
      margin: 0 auto;
    }

    @include mq(screen--md) {
      order: 2;
      flex: 1;
      width: auto;
      margin: $padding--md 0 0 $padding--lg;
    }

    .std-content h2 {
      color: white;
    }

    .std-content blockquote {
      margin: $padding--md 0;
      padding: 0;
    }

    .std-content blockquote p {
      font-style: italic;
      font-weight: $font-weight--bold;
      font-size: $font-size--xs;
      color: white;
      line-height: $line-height--lg;
      text-align: left;
    }

}

.tradesperson-promo__nav {
  @extend .img-gallery__nav;
}