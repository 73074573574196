// Brand colors

$darken: 5%;

$color-brand--red: #ba202f;
$color-brand--red-hover: darken($color-brand--red, $darken);
$color-brand--blue: #1d3057;
$color-brand--blue-hover: darken($color-brand--blue, $darken);
$color-brand--green: #8fbb8f;

$grad-brand--blue: linear-gradient(0deg, rgba(18,30,56,1) 0%, rgba(29,48,87,1) 100%);
$grad-brand--red: linear-gradient(0deg, rgba(150,26,39,1) 0%, rgba(186,32,47,1) 100%);
$grad-brand--grey: linear-gradient(0deg, rgba(234,231,231,1) 0%, rgba(244,244,244,1) 100%);;

$color-ui--grey-light: #f4f4f4;
$color-ui--grey-mid: #727272;
$color-ui--grey-dark: #292929;

$color-validate--ok: rgb(0, 201, 0);
$color-validate--fail: red;

$color-social--youtube: #CD0C24;
$color-social--twitter: #009CDC;
$color-social--facebook: #204889;

// Animation

$transition--lg: 0.33s ease-out;
$transition--md: 0.25s ease-out;
$transition--sm: 0.1s ease-out;

// Fonts
$font-weight--bold: 700;

$font-size--xxl: 5rem;
$font-size--xl: 4rem;
$font-size--lg: 3rem;
$font-size--md: 2.4rem;
$font-size--sm: 2.1rem;
$font-size--xs: 1.8rem;
$font-size--xxs: 1.4rem;

$kerning--md: -0.2rem;
$kerning--sm: -0.1rem;

$line-height--lg: 1.75;
$line-height--md: 1.5;
$line-height--sm: 1.25;

// UI styles

$padding-global: 5rem;

$padding--xl: $padding-global*2;
$padding--lg: $padding-global;
$padding--md: $padding-global/2;
$padding--sm: $padding-global/4;

$border-radius--lg: 1rem;
$border-radius--md: 0.5rem;

$drop-shadow: 0 0 8px rgba(0,0,0,0.1)