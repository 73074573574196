// =================================================
// GLOBAL HELPERS
// =================================================

// Text alignment

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

// Images

.flex-img img {
  display: block;
  width: 100%;
  height: auto;
}