// ====================================
// CONTACT INFO STYLES
// ====================================

.contact-info-outer {
  margin: 0 0 $padding--lg;
  padding: 0 0 $padding--lg;

  border-bottom: 1px solid $color-ui--grey-light;
}

.contact-info + .contact-info {
  margin: $padding--lg 0 0;  
}

.contact-info-list {
  list-style-type: none;
}

.contact-info-list__item + .contact-info-list__item {
  margin: $padding--md 0 0;
}

.contact-info-list__info,
.contact-info-list__link {
  display: flex;

    svg {
      width: 2rem;
      height: 2rem;
      margin: 0.25rem 2rem 0 0;
    }

}

.contact-info-list__info svg {
  fill: $color-brand--blue;
}

.contact-info-list__link {
  color: $color-brand--blue;

    &:hover {
      color: $color-brand--red;
    }

}

.contact-info-list__text {
  line-height: $line-height--md;
}

// Variants

.pg-footer-social {
  font-size: $font-size--xs;

    .contact-info-list {
      display: flex;
      flex-direction: column;
      align-items: center;

        @include mq(screen--xs) {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: center;
        }

        @include mq(screen--md) {
          align-items: flex-start;
        }

    }

    .contact-info-list__link {
      color: $color-ui--grey-mid;
      text-decoration: none;

        &:hover {
          color: $color-brand--red;
        }
        
    }

}