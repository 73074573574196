// =================================================
// BREAKPOINT MEASUREMENTS
// =================================================

$screen--xxl: 1800px !default;
$screen--xl: 1600px !default;
$screen--lg: 1280px !default;
$screen--md: 1024px !default;
$screen--sm: 768px !default;
$screen--xs: 650px !default;
$screen--xxs: 450px !default;

@mixin mq($point) {

  @if $point == screen--xxl {
    @media (min-width: $screen--xxl) { @content; }
  }
  
  @if $point == screen--xl {
    @media (min-width: $screen--xl) { @content; }
  }

  @if $point == screen--lg {
    @media (min-width: $screen--lg) { @content; }
  }

  @if $point == screen--lg-land {
    @media (min-width: $screen--lg) and (orientation: landscape) { @content; }
  }

  @if $point == screen--md {
    @media (min-width: $screen--md) { @content; }
  }

  @if $point == screen--md-land {
    @media (min-width: $screen--md) and (orientation: landscape) { @content; }
  }

  @if $point == screen--md-port {
    @media (min-width: $screen--md) and (orientation: portrait) { @content; }
  }

  @if $point == screen--sm {
    @media (min-width: $screen--sm) { @content; }
  }

  @if $point == screen--sm-xs {
    @media (min-width: $screen--sm) and (min-height: $xs) { @content; }
  }

  @if $point == screen--sm-land {
    @media (min-width: $screen--sm) and (orientation: landscape) { @content; }
  }

  @if $point == screen--sm-port {
    @media (min-width: $screen--sm) and (orientation: portrait) { @content; }
  }

  @if $point == screen--xs {
    @media (min-width: $screen--xs) { @content; }
  }

  @if $point == screen--xs-land {
    @media (min-width: $screen--xs) and (orientation: landscape) { @content; }
  }

  @if $point == screen--xs-port {
    @media (min-width: $screen--xs) and (orientation: portrait) { @content; }
  }

  @if $point == screen--xxs {
    @media (min-width: $screen--xxs) { @content; }
  }

  @if $point == screen--xxs-land {
    @media (min-width: $screen--xxs) and (orientation: landscape) { @content; }
  }

  @if $point == screen--xxs-port {
    @media (min-width: $screen--xxs) and (orientation: portrait) { @content; }
  }

  @if $point == screen--land {
    @media (orientation: landscape) { @content; }
  }

  @if $point == screen--port {
    @media (orientation: portrait) { @content; }
  }

}

@mixin multi-mq($orientation, $breakpoint) {
  @media (orientation: $orientation), (min-width: $breakpoint) { @content; }
}