// =================================================
// SERVICES GRID STYLES
// =================================================

.services-grid-outer {
  padding: $padding--lg 0;

    @include mq(screen--lg) {
      padding: $padding--xl 0;
    }

}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: -$padding--md;

  list-style-type: none;
}

.services-grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  padding: $padding--md;

  text-align: center;

    @include mq(screen--sm) {
      width: 33.333%;
    }

    @include mq(screen--lg) {
      width: 20%;
    }

}

.services-grid__link {
  display: block;

  text-decoration: none;
  
    &:hover {
    
      .services-grid__icon {
        fill: $color-brand--red;
      }

    }

    @include ie-support {
      width: 100%;
    }

}

.services-grid__icon {
  width: 5rem;
  height: 5rem;
  margin: 0 0 $padding--md;

  fill: $color-brand--blue;

    @include mq(screen--lg) {
      width: 8rem;
      height: 8rem;
      margin: 0 0 $padding--lg;
    }

}

.services-grid__title {
  height: 4.5rem;
  margin: 0 0 $padding--sm;

  font-weight: $font-weight--bold;
  font-size: $font-size--xs;
  color: $color-brand--blue;
  line-height: $line-height--sm;
  text-decoration: none;

    @include mq(screen--lg) {
      margin: 0 0 $padding--md;

      font-size: $font-size--sm;
    }

}

.services-grid__text {
  font-size: $font-size--xxs;
  line-height: $line-height--md;
  color: $color-brand--blue;
  text-decoration: none;
}