// =================================================
// PAGINATION STYLES
// =================================================

.pagination {
  display: flex;
  justify-content: center;
  margin: $padding--lg 0 0;

    @include mq(screen--lg) {
      margin: $padding--xl 0 0;
    }

    button {
      margin: 0.5rem;
    }

}

.pagination__pg-number {
  display: block;
  width: 3rem;
  height: 3rem;

  font-family: inherit;
  font-weight: $font-weight--bold;
  font-size: $font-size--xxs;
  color: $color-brand--blue;
  line-height: calc(3rem - 4px);
  text-align: center;

  border: 2px solid $color-ui--grey-mid;
  border-radius: 2rem;

    &:hover {
      background-color: white;
      cursor: pointer;
    }

    &.mixitup-control-active {
      border-color: $color-brand--blue;
      background-color: $color-brand--blue;

      color: white;
    }

}

.pagination__pg-prev,
.pagination__pg-next {
  display: block;
  width: 3rem;
  height: 3rem;
  padding: 0;

  border: 0;
  background-color: $color-brand--red;

  border-radius: 1.5rem;

    svg {
      display: block;
      width: 1rem;
      height: 1rem;
      margin: 0 auto;

      fill: white;
    }

    &:hover {
      background-color: $color-brand--red-hover;
    }

}

.pagination__pg-prev {
  
  svg {
    transform: rotate(-180deg);
  }

}