// ====================================
// CTA PANEL STYLES
// ====================================

.cta-panel {
  display: flex;
  padding: $padding--md $padding--sm;
  border-radius: $border-radius--md;

  color: white;

    @include mq(screen--lg) {
      padding: $padding--md $padding--md $padding--md $padding--sm;
    }
}

.cta-panel__icon {
  width: 4rem;
  height: 4rem;
  margin: 0 $padding--sm 0 0;

  fill: white;
}

.cta-panel__content {
  flex: 1;
}

.cta-panel__title {
  margin: 0 0 $padding--sm;
}

.cta-panel__text {
  margin: 0 0 $padding--sm;

  font-size: $font-size--xxs;
  line-height: $line-height--md;
}

.cta-panel__link {
  margin: $padding--sm 0 0;
  font-size: $font-size--xs;
  color: inherit;

    &:hover {
      color: rgba(white, 0.75);
    }

}

// Variants

.cta-panel--blue {
  background: $grad-brand--blue;
}

.cta-panel--red {
  background: $grad-brand--red;
}

.hero-ctas {

  .cta-panel__text {
    display: none;
  
      @include mq(screen--lg) {
        display: block;
      }
  
  }

}

.promo {
  
  .cta-panel {
    display: none;

      @include mq(screen--sm) {
        display: flex;
        width: 35%;

        position: absolute;
        top: 5rem;
        right: $padding--md;
        z-index: 1;
      }

      @include mq(screen--lg) {
        right: $padding--lg;

        width: 25%;
      }

  }

}