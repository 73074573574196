// =================================================
// TOOLTIP STYLES
// =================================================

.tooltip {
  display: flex;
  padding: $padding--md;

  border-radius: $border-radius--md;
  box-shadow: $drop-shadow;
}

.tooltip__icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 $padding--sm 0 0;

    @include mq(screen--sm) {
      width: 4rem;
      height: 4rem;
      margin: 0 $padding--md 0 0;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: $color-brand--red;

        @include mq(screen--sm) {
          width: 4rem;
          height: 4rem;
        }

    }

}

.tooltip__text {
  flex: 1;

  font-size: $font-size--xxs;

    @include mq(screen--sm) {
      font-size: $font-size--xs;
    }

    &.std-content p {
      margin: 0;
    }

}
