// =================================================
// IMAGE GALLERY STYLES
// =================================================

.img-gallery {
  margin: 0 0 $padding--md;
}

.img-gallery__nav {
  display: flex;
  justify-content: center;
  width: 100%;

  list-style-type: none;

    li {
      margin: 0 1rem;
    }

    li.tns-nav-active {
      
      button {
        background-color: $color-brand--red;
      }

    }

    button {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0;

      background-color: $color-ui--grey-mid;
      border: none;
      border-radius: 100%;
    }

}