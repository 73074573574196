// =================================================
// SUB-TITLE STYLES
// =================================================

.sub-title {
  margin: 0 0 $padding--md;
  
  font-size: $font-size--md;
  font-weight: $font-weight--bold;
  color: $color-brand--blue;

    @include mq(screen--lg) {
      font-size: $font-size--lg;
    }

}

.sub-title--lg {
  
  @include mq(screen--sm) {
    margin: 0 0 $padding--lg;
  }

}

.sub-title--white {
  color: white;
}
