// =================================================
// NEWSLETTER SIGNUP STYLES
// =================================================

.newsletter-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: $padding--lg 0 0;

    @include mq(screen--md) {
      //flex-direction: row;
      align-items: flex-start;
      margin: 0;
    }
  
    label {
      display: block;
      margin: 0 0 $padding--sm;

      font-size: $font-size--xs;
    }

}

.newsletter-signup__form {
  display: flex;
  width: 100%;
}

input[type="email"].newsletter-signup__field {
  flex: 1;
  height: 5rem;
  padding: 0 1rem;

  border: 1px solid $color-ui--grey-mid;
  border-radius: $border-radius--md 0 0 $border-radius--md;

  font-size: $font-size--xs;
}

.newsletter-signup__submit-btn {
  position: relative;
  width: 5rem;
  height: 5rem;

  background-color: $color-brand--red;
  border-radius: 0 $border-radius--md $border-radius--md 0;
  overflow: hidden;

    &:hover {
      background-color: $color-brand--red-hover;
    }

    input[type="submit"] {
      position: relative;
      z-index: 2;

      width: 5rem;
      height: 5rem;

      border: none;
      transition: $transition--sm;
      background: none;

      cursor: pointer;
    }

    svg {
      position: absolute;
      top: 1.75rem;
      right: 1.75rem;
      z-index: 1;

      width: 1.5rem;
      height: 1.5rem;

      fill: white;
    }

}

.response {
  margin: $padding--sm 0 0;
  padding: $padding--sm;

  border-radius: $border-radius--md;

  font-size: $font-size--xxs;
  color: white;

    a {
      color: inherit;
    }

}

.response--fail {
  background-color: $color-brand--red;
}

.response--success {
  background-color: $color-brand--blue;
}