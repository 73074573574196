// =================================================
// PAGE FOOTER STYLES
// =================================================

.pg-footer {
  padding: $padding--lg 0;

  border-top: 1px solid $color-ui--grey-light;

    @include mq(screen--sm) {
      padding: $padding--xl $padding--lg;
    }

    @include mq(screen--lg) {
      width: $screen--lg;
      margin: 0 auto $padding--md;
    }

}

.pg-footer__ctas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0 $padding--lg;

    @include mq(screen--sm) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 $padding--xl;
    }

  .cta-nav {
    width: 100%;
    margin: $padding--md 0;

      @include mq(screen--sm) {
        width: auto;
        margin: 0;
      }

  }

}

.pg-footer__links {
  
  @include mq(screen--md) {
    display: flex;
  }

}

.pg-footer-nav {
  padding: 0 0 $padding--lg;
  margin: 0 0 $padding--lg;

  border-bottom: 1px solid $color-ui--grey-light;

    @include mq(screen--md) {
      width: 50%;
      padding: 0 $padding--lg 0 0;
      margin: 0;

      border-bottom: none;
      border-right: 1px solid $color-ui--grey-light;
    }

}

.pg-footer-nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin: -1rem;

  list-style-type: none;

    @include mq(screen--xs) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    @include mq(screen--md) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    li {
      padding: 1rem;
      
        @include mq(screen--md) {
          width: 50%;
        }

    }
    
    a {
      font-size: $font-size--xs;
      color: $color-ui--grey-mid;
      text-decoration: none;

        &:hover {
          color: $color-brand--red;
        }

    }

}

.pg-footer-social {

  @include mq(screen--md) {
    width: 25%;
    padding: 0 $padding--lg;
  }

}

.pg-footer-signup {
  width: 30rem;
  margin: 0 auto;
  
  @include mq(screen--md) {
    width: 25%;
  }

}

.copyright {
  margin: $padding--lg 0 0;

  font-size: $font-size--xxs;
  color: $color-brand--blue;
}

