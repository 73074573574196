// =================================================
// LAYOUT STYLES
// =================================================

.outer {
  display: block;
  position: relative;
}

.outer--grey-light {
  background: $color-ui--grey-light;
}

.inner {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 $padding--md;

    @include mq(screen--sm) {
      padding: 0 $padding--lg;
    }

}

.inner--sm {

  @include mq(screen--sm) {
    width: $screen--sm;
  }

}

.inner--md {

  @include mq(screen--md) {
    width: $screen--md;
  }

}

.inner--lg {

  @include mq(screen--lg) {
    width: $screen--lg;
  }

}

.inner--xl {

  @include mq(screen--xl) {
    width: $screen--xl;
  }

}

.inner--xxl {
  
  @include mq(screen--xxl) {
    width: $screen--xxl;
  }

}

.pg-content__sections {
  margin: $padding--lg 0;

    @include mq(screen--lg) {
      margin: $padding--xl 0;
    }

}

.overlap {
  padding: $padding--lg 0;
  margin: 0 0 $padding--lg;

  background-color: white;

    @include mq(screen--lg) {
      position: relative;
      z-index: 2;
      
      max-width: calc(1280px - 10rem);
      margin: 0 auto;
      padding: $padding--xl 0;

      transform: translateY(-10rem);

      box-shadow: $drop-shadow;
      border-radius: $border-radius--md;
    }

}

.with-overlap {
  
  @include mq(screen--lg) {
    padding: 0 0 10rem;
  }

}