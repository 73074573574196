// ====================================
// HOW IT WORKS STYLES
// ====================================

.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $padding--md;

    @include mq(screen--sm) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      padding: 0 $padding--lg;
    }

}

.how-it-works__step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
  padding: $padding--md;

    @include mq(screen--sm) {
      width: percentage(1/3);
    }
    
}

.how-it-works__icons {
  position: relative;
  margin: 0 0 $padding--md;
}

.how-it-works__marker {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  display: block;
  width: 3rem;
  height: 3rem;

  font-size: $font-size--sm;
  font-weight: $font-weight--bold;
  color: $color-brand--blue;
  text-align: center;
  line-height: 3rem;

  background-color: $color-brand--green;
  border-radius: 1.5rem;
}

.how-it-works__icon-1 {
  position: relative;
  z-index: 1;

  width: 10rem;
  height: 10rem;
  margin: 1.5rem 0 2rem;
  padding: 2.5rem;

  border-radius: 100%;
  background-color: white;
  box-shadow: $drop-shadow;

    @include mq(screen--lg) {
      width: 12rem;
      height: 12rem;
      padding: 3rem;
    }

    svg {
      width: 5rem;
      height: 5rem;

      fill: $color-brand--blue;

        @include mq(screen--lg) {
          width: 6rem;
          height: 6rem;
        }

    }

}

.how-it-works__icon-2 {
  position: absolute;
  bottom: 0;
  right: -5rem;
  z-index: 2;

  width: 7rem;
  height: 7rem;
  padding: 2rem;

  border-radius: 100%;
  background-color: white;
  box-shadow: $drop-shadow;

    @include mq(screen--lg) {
      right: -6rem;
      width: 8rem;
      height: 8rem;
    }

    svg {
      width: 3rem;
      height: 3rem;

      fill: $color-brand--red;

        @include mq(screen--lg) {
          width: 4rem;
          height: 4rem;
        }

    }

}

.how-it-works__text {
  font-size: $font-size--xs;
  line-height: $line-height--md;
  text-align: center;

    a {
      color: $color-brand--red;
    }

    a:hover {
      color: $color-brand--blue;
    }

    @include ie-support {
      display: block;
      width: 100%;
    }

}